import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import axios from 'axios';
import { FILE_HOST_URL } from '../../../config';

const title = "Концертация видео на GIF";

export default function ConvertHandle() {
    const [isLoading, setIsLoading] = useState(false);
    const [previewVideo, setPreviewVideo] = useState(false);

    const isMounted = useIsMounted();

    const defaultValues = {
        file: null,
        begin: 0,
        end: 10,
        quality: 360,
    };

    const { register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {
        document.title = title;
    }, []);

    const onSubmit = async (data) => {
        if (isLoading) return;

        if (data.file?.length > 0) {
            setIsLoading(true);

            let res = await axios.post(`${FILE_HOST_URL}/convert-to-gif.php`, arrayToFormData({
                video: data.file[0],
                begin: data.begin,
                quality: data.quality,
                duration: Number.parseInt(data.end) - Number.parseInt(data.begin)
            }), { responseType: "blob" });

            if (isMounted) {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: "image/gif" }));
                const link = document.createElement('a');

                link.href = url;

                let download = 'test.gif';

                link.setAttribute('download', download); //or any other extension

                document.body.appendChild(link);

                link.click();
                link.remove();

                setIsLoading(false);
            }
        } else {

        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to="/settings" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center mb-3">
                            <div className="col-md-4">
                                {previewVideo && <video className="w-100" controls src={previewVideo}></video>}
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Видео:</p>
                                        <input {...register("file", { required: true })} type="file" accept="video/*" className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);
                                                reader.onloadend = function (e) {
                                                    setPreviewVideo(reader.result)
                                                }
                                            }
                                        }} />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Начало (секунды) *:</p>
                                        <input {...register("begin", { required: true })} className={"formColOneRowFlexInput " + (errors.begin?.type)} type="text" placeholder="С какого секунда" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Конец (секунды) *:</p>
                                        <input {...register("end", { required: true })} className={"formColOneRowFlexInput " + (errors.end?.type)} type="text" placeholder="До какого секунда" />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Качество *:</p>
                                        <select {...register("quality", { required: true })} className={"formColOneRowFlexInput " + (errors.quality?.type)} >
                                            <option value="360">360p</option>
                                            <option value="480">480p</option>
                                            <option value="720">720p</option>
                                            <option value="1080">1080p</option>
                                            <option value="1440">1440p</option>
                                            <option value="2160">2160p</option>
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Конвертировать на GIF")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}