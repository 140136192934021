import axios from 'axios';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../components/context';
import Sidebar from '../../../components/Settings/Sidebar';
import { useIsMounted } from '../../../utils/functions';
import { get } from '../../../utils/request';

import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip-lite';

const controllerName = "member";
const title = "Пользователи";

const CancelToken = axios.CancelToken;
let cancel;

export default function Users() {
    const authContext = React.useContext(AuthContext);

    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("user.id");
    const [limit, setLimit] = useState(20);
    const [sortType, setSortType] = useState("desc");
    const [page, setPage] = useState(1);

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${page ? `&page=${page}` : ``}${sortType ? `&sort_type=${sortType}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.models);
                setPagination(res.pagination)
            }

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        fetchModels();
    }, [search, sort, page, limit, sortType]);

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>
                                </h3>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center justify-content-end mb-3 col-12 col-md-3">
                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("user.id");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>ID {sort == "user.id" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("full_name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>ФИО {sort == "full_name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("username");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Логин {sort == "username" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("email");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Почта {sort == "email" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("user.created_at");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата регистрации {sort == "user.created_at" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("user.status");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Статус {sort == "user.status" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("balance");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Баланс {sort == "balance" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}

                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td className="text-start">{model.id}</td>
                                                    <td className="text-start">{model.full_name}</td>
                                                    <td className="text-start">{model.username}</td>
                                                    <td className="text-start">{model.email ? model.email : <i>(не указано)</i>}</td>
                                                    <td className="text-start">{model.created_at ? new Date(model.created_at * 1000).toISOString().slice(0, 10).split("-").reverse().join(".") : <i>(не указано)</i>}</td>
                                                    <td className="text-start">{model.status == 10 ? <span className='text-success'>Актив</span> : model.status == 9 ? <span className='text-danger'>Не актив</span> : <span className='text-danger'>Удален</span>}</td>
                                                    <td className="text-start">
                                                        <NavLink to={`/users/transactions/${model.id}`}>
                                                            <span className={model.balance > 0 ? 'text-success' : 'text-white'}>{model.balance}</span>
                                                        </NavLink>
                                                    </td>
                                                    <td className='pe-3'>
                                                        <Tooltip content={"Транзакции"} className='target d-inline'>
                                                            <NavLink to={`/users/transactions/${model.id}`} className="text-white">
                                                                <i className="fa fa-money"></i>
                                                            </NavLink>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                            marginPagesDisplayed={7}
                            pageRangeDisplayed={5}
                            onPageChange={(p) => {
                                setPage(p.selected + 1);
                            }}
                            forcePage={parseInt(page - 1)}
                            containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                            activeClassName={'active'}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />}
                    </div>
                </div>
            </div>
        </section >
    );
}