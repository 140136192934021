import React from "react";
import { useState } from "react";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import { arrayToFormData, useIsMounted } from "../utils/functions";
import { get, post } from "../utils/request";

const customStyles = {
    content: {
        top: '12%',
        left: '25%',
        right: '25%',
        bottom: '12%',
        backgroundColor: "#232636"
    },
};

export default function SelectExistingMusic({ isOpen, setIsOpen, author_id, album_id, fetchAlbum }) {
    function closeModal() {
        setIsOpen(false);
    }

    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ids, setIds] = useState([]);
    const [search, setSearch] = useState("");

    const isMounted = useIsMounted();

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            let res = await get(`album/list?limit=1000&author_id=${author_id}`);

            if (isMounted) {
                setModels(res);
                setIds([]);

                setIsLoading(false);
            }
        }

        fetchData();
    }, [isOpen]);

    const save = async () => {
        if (ids.length > 0) {
            let res = await post(`album/set-album?album_id=${album_id}`, arrayToFormData({
                ids
            }));

            if (res.status == "success") {
                toast.success("Успешно добавлены");

                if (fetchAlbum) fetchAlbum();
            } else {
                toast.error(res.message);
            }

            closeModal();
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <div className="d-flex">
                <div className="w-100">
                    <div className="w-100">
                        <input type="text" className="form-control" placeholder="Поиск" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>
                <div className="col ms-3">
                    <div className="text-end">
                        <a role="button" onClick={closeModal}><i className="fa fa-close fa-2x text-white"></i></a>
                    </div>
                </div>
            </div>

            <table className="table text-white table-borderless table-hover text-center mt-4">
                <thead>
                    <tr>
                        <th style={{ width: "50px" }}></th>
                        <th style={{ width: "40px" }}></th>
                        <th className="text-start">Название</th>
                        <th>Дата релиза</th>
                    </tr>
                </thead>

                <tbody>
                    {!isLoading && models.filter(model => search === "" || model.name.toLowerCase().indexOf(search.toLowerCase()) > -1).length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                    {isLoading && <tr className="text-white text-center"><td colSpan="10">
                        <i className="fa fa-spinner fa-2x rotating text-white"></i>
                    </td></tr>}
                    {models.filter(model => search === "" || model.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map((model, index) => {
                        return (
                            <tr key={index} className="vertical-align-middle text-white">
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={model.id} checked={ids.includes(model.id)} onChange={(e) => {
                                            if (e.target.checked) {
                                                setIds([...ids, model.id])
                                            } else {
                                                setIds(ids.filter(id => id != model.id))
                                            }
                                        }} id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault"> </label>
                                    </div>
                                </td>
                                <td className="text-end">
                                    <img className="border-radius-2" src={model.image} alt="" style={{ width: "40px" }} />
                                </td>
                                <td className="text-start">{model.name}</td>
                                <td className="text-white">
                                    {model.release_date && model.release_date.split(" ")[0].split("-").reverse().join(".")}
                                </td>
                            </tr>

                        );
                    })}
                </tbody>
            </table>

            <div className="text-end">
                <button className={`btn ${ids.length == 0 ? 'btn-danger' : 'btn-success'}`} onClick={() => {
                    if (ids.length > 0) {
                        save();
                    } else {
                        closeModal();
                    }

                }}>
                    {isLoading ? <i className="fa fa-spinner rotating"></i> : (ids.length == 0 ? 'Закрыть' : 'Добавить')}
                </button>
            </div>
        </Modal>
    );
}