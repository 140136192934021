import React from "react";
import axios from "axios";
import { useState } from "react";
import { arrayToFormData, useIsMounted } from "../utils/functions";
import Modal from 'react-modal';
import MusicForm from "./forms/MusicForm";
import { post } from "../utils/request";
import { toast } from "react-toastify";
import moment from "moment";
import { FILE_HOST_URL } from "../config";

const customStyles = {
    content: {
        top: '135px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        backgroundColor: "#232636"
    },
};

export default function UploadMusic(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [id, setId] = useState(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const isMounted = useIsMounted();

    React.useEffect(() => {
        let fetch = async () => {
            setIsLoading(true);

            window.onbeforeunload = function (e) {
                e = e || window.event;
            
                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Закрыть?';
                }
            
                // For Safari
                return 'Закрыть?';
            };

            let res = await axios.post(`${FILE_HOST_URL}/hot-upload.php`, arrayToFormData({
                music: props.music
            }), {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100);
                }
            });

            if (res && res.data && res.data.status === "success") {

                let name = props.music.name.replace(".mp3", "").replace(".wav", "");

                let response = await post(`music/create`, arrayToFormData({
                    filename_mp3: res.data.filename_mp3,
                    filename_mp3_64: res.data.filename_mp3_64,
                    filename_ogg: res.data.filename_ogg,
                    filename_ogg_64: res.data.filename_ogg_64,
                    name: name.indexOf(" - ") > -1 ? name.split(" - ")[1] : name,
                    duration: res.data.duration,
                    singers: [props.singer_id],
                    albums: [props.album_id],
                    compositors: props.compositors,
                    author_texts: props.author_texts,
                    producers: [],
                    leybls: [],
                    categories: [8],
                    status: 3,
                    is_premiere: 0,
                    // is_hit: 0,
                    release_date: moment().format("YYYY-MM-DD") + " 11:00:00",
                }));

                if (response?.status === "success") {
                    if (isMounted) {
                        setIsLoading(false);
                        setId(response.model.id);
                        closeModalWithFetch();
                    }
                }
            } else if (res && res.data && res.data.status === "error") {
                toast.error("Произошла ошибка на стороне файлового сервера при загрузке или конвертации файла");
            } else {
                toast.error("Произошла ошибка на стороне файлового сервера, возможно не хватило места на диске");
            }

            setIsLoading(false);
        }

        if (props.music && !props.wait) {
            fetch();
        }

    }, [props.music]);

    function openModal() {
        if (!id)
            toast.info("Файл ещё загружается");
        else
            setIsOpen(true);
    }

    function closeModalWithFetch() {
        setIsOpen(false);

        if (props.fetchData) {
            props.fetchData();
        }

        if (props.deleteThis) {
            props.deleteThis(props.music.name);
        }
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <li className="list-group-item list-group-item-dark py-2">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                {id && <MusicForm id={id} closeModal={closeModal} closeModalWithFetch={closeModalWithFetch} />}
            </Modal>
            {!props.wait ? <>
                <div className="d-flex">
                    <a role="button" className="w-100 text-white d-block">
                        {props.music.name}
                    </a>
                    {!isLoading && <>
                        <a role="button" className="text-white me-3" onClick={() => openModal()}>
                            <i className="fa fa-pencil"></i>
                        </a>
                        <a role="button" className="text-white" onClick={() => props.deleteThis && props.deleteThis()}>
                            <i className="fa fa-trash"></i>
                        </a>
                    </>}
                </div>
                {isLoading && progress != 100 && <div>
                    <p className="text-white">Загрузка файла... </p>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress mb-4">
                                <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                            </div>
                        </div>
                    </div>

                </div>}
                {isLoading && progress == 100 && <div>
                    <p className="text-white">Конвертация файла... </p>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress mb-4">
                                <div className={`progress-bar progress-bar-striped progress-bar-animated`} role="progressbar" style={{ width: `${100}%` }}></div>
                            </div>
                        </div>
                    </div>
                </div>}
            </> : <div>
                <p className="text-white">{props.music.name} - Ожидания очереди... </p>
                <div className="row">
                    <div className="col-12">
                        <div className="progress mb-4">
                            <div className={`progress-bar progress-bar-striped progress-bar-animated`} role="progressbar" style={{ width: `${100}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>}
        </li>
    );
}