import React, { useContext, useState } from 'react';
import { AuthContext } from '../components/context';
import { useIsMounted } from '../utils/functions';
import { post } from '../utils/request';
import { useForm } from 'react-hook-form';

export default function Home() {
    const authContext = useContext(AuthContext);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState("");

    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const submitHandler = async (data) => {
        setIsLoading(true);

        try {
            let res = await post('user/login', {
                username: data.username,
                password: data.password,
            });

            if (res?.status === 'error') {
                if (isMounted) {
                    setError("username", { type: 'required', message: res.message })
                }
            } else {
                if (isMounted) {
                    authContext.login({ ...res.user, token: res.token });
                }
            }

        } catch (ex) {

        }

        setIsLoading(false);
    };

    return <div className="">
        <div className="admin-index">
            <div className="admin-index__bg"></div>
            <div className="admin-index__content">
                <div className="login">
                    <div className="admin-index__logo">
                        <img src="/img/admin_index_logo.png" alt="" />
                    </div>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <input name="username" autoComplete="off" placeholder="Логин" type="text" {...register("username", { required: true, maxLength: 80 })} />
                        <p className="help-block help-block-error text-danger">{errors.username?.type === 'required' && (errors.username?.message || "Необходимо заполнить «Логин»")}</p>
                        <br />

                        <input name="password" type="password" autoComplete="off" placeholder="Пароль" {...register("password", { required: true, maxLength: 80 })} />
                        <p className="help-block help-block-error text-danger">{errors.password?.type === 'required' && "Необходимо заполнить «Пароль»"}</p>
                        <div className="login__items">
                            <div className="login__item justify-content-end">
                                <button type="submit">
                                    {isLoading ? (
                                        <i className="fa fa-spinner fa-2x rotating "></i>
                                    ) : ("Войти")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}