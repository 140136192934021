export const initialPlaylistState = [];

export const playlistReducer = (prevState, action) => {
    switch (action.type) {
        case 'FILL':
            return [...action.payload];

        case 'CLEAR':
            return [];

        default:
            return prevState;
    }
};
