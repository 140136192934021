import axios from 'axios';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../components/context';
import Sidebar from '../../../components/Settings/Sidebar';
import { useIsMounted } from '../../../utils/functions';
import { get } from '../../../utils/request';

import ReactPaginate from 'react-paginate';

const controllerName = "admin";
const title = "Админы";

const CancelToken = axios.CancelToken;
let cancel;

export default function Admins() {
    const authContext = React.useContext(AuthContext);

    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${page ? `&page=${page}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.models);
                setPagination(res.pagination)
            }

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        fetchModels();
    }, [search, sort, page, limit]);

    const handleDelete = async (id) => {
        if (id === authContext.authState.user.id) {
            toast.error("Нельзя удалить свой аккаунт");
            return;
        }

        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setModels(models.filter((model) => model.id !== id));
                    toast.success(res.message);
                }
            }
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        Настройки
                                    </span>
                                </h3>
                                <NavLink to={`/settings/${controllerName}/create`} className="show-all btn btn-success fs-12">
                                    <span>
                                        Добавить
                                    </span>
                                    <i className="fa fa-plus"></i>
                                </NavLink>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <select className="form-select form-select-sm w-auto" value={sort} onChange={(e) => setSort(e.target.value)}>
                                    <option value=""> - Сорт - </option>
                                    <option value="id">Дата добавления</option>
                                    <option value="full_name">Ф.И.О.</option>
                                </select>

                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-md-3">
                                <Sidebar />
                            </div>
                            <div className="col-md-9">
                                {!isLoading && models.length === 0 && <h4 className="text-white">Ничего не найдено</h4>}
                                {isLoading ? <div className="text-center">
                                    <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                </div> : <div className="row">
                                    {models.map((model, index) => {
                                        return (
                                            <div className="col-md-4 col-lg-3 mb-4" key={index}>
                                                <NavLink to={"/settings/admin/" + model.id} className="music-item">
                                                    <div >
                                                        <div className="image rounded-circle singer-image">
                                                            <img src={model.image} className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="title text-center">
                                                        <p className="fs-16">{model.full_name}</p>
                                                    </div>
                                                    <div className="singer text-center">
                                                        <p >{model.role}</p>
                                                    </div>
                                                </NavLink>
                                                <div className="text-center text-white mt-1">
                                                    <a role="button" onClick={() => {
                                                        handleDelete(model.id)
                                                    }}><i className="fa fs-16 fa-trash"></i></a>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>
                        </div>

                        {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                            marginPagesDisplayed={7}
                            pageRangeDisplayed={5}
                            onPageChange={(p) => setPage(p.selected + 1)}
                            forcePage={parseInt(page - 1)}
                            containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                            activeClassName={'active'}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />}
                    </div>
                </div>
            </div>
        </section >
    );
}