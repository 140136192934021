import React, { useRef, useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { get, post } from '../../utils/request';
import axios from 'axios';
import { arrayToFormData, currentDateTime, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { AuthContext } from '../../components/context';
import Select from 'react-select';
import CheckBox from '../../components/forms/CheckBox';
import SelectAlbumModal from '../../components/SelectAlbumModal';

import Tooltip from 'react-tooltip-lite';
import SelectCategoryModal from '../../components/SelectCategoryModal';

const controllerName = "music";
const title = "Музыка";

const CancelToken = axios.CancelToken;
let cancel;

export default function Music() {
    const authContext = React.useContext(AuthContext);

    let history = useHistory();

    const { album_id } = useParams();

    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isStash, setIsStash] = useState(false);

    const [ids, setIds] = useState([]);

    const [isOpenAlbumModal, setIsOpenAlbumModal] = useState(false);
    const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("release_date");
    const [author_id, setAuthor_id] = useState("");
    const [sortType, setSortType] = useState("desc");
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);

    const [play, setPlay] = useState(null);
    const [album, setAlbum] = useState(null);
    const audioRef = useRef();

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${sortType ? `&sort_type=${sortType}` : ``}${page ? `&page=${page}` : ``}${album_id ? `&album_id=${album_id}` : ``}${author_id ? `&author_id=${author_id.value}` : ``}${isStash ? `&stash=1` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.models);
                setAlbum(res.album);
                setPagination(res.pagination)
            }

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        fetchModels();
    }, [search, sort, sortType, page, limit, author_id, isStash]);

    React.useEffect(() => {
        setIds([]);
    }, [isStash]);

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
    }, [play]);

    const handleDelete = async (id) => {
        if (window.confirm("Удалить навсегда?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setPlay(null);
                    setModels(models.filter((model) => model.id !== id));
                    toast.success(res.message);
                }
            }
        }
    }

    const update = () => {
        if (ids.length > 0) {
            history.push('/music/update/' + ids.join(","));
        } else {
            toast("Выберите объектов", { type: "error" });
        }
    }

    const stash = async (_ids = []) => {
        if (_ids.length > 0 || ids.length > 0) {
            if (!isStash ? window?.confirm("Переместить выбранных в корзину?") : window?.confirm("Восстановить выбранных из корзины?")) {
                if (isStash) {
                    let res = await post("music/restore", arrayToFormData({
                        ids
                    }));

                    if (res.status == "success") {
                        toast("Успешно восстановлены", { type: "success" });
                        fetchModels();
                        setIds([]);
                    }
                } else {
                    let res = await post("music/stash", arrayToFormData({
                        ids: _ids.length > 0 ? _ids : ids
                    }));

                    if (res.status == "success") {
                        toast("Успешно перемещены в корзину", { type: "success" });
                        fetchModels();
                        setIds([]);
                    }
                }

            }
        } else {
            toast("Выберите объектов", { type: "error" });
        }
    }

    const deleteAll = async (_ids = []) => {
        if (ids.length > 0) {
            if (window?.confirm("Удалить выбранных?")) {
                let res = await post("music/delete-all", arrayToFormData({
                    ids
                }));

                if (res.status == "success") {
                    toast("Успешно удалены", { type: "success" });
                    fetchModels();
                    setIds([]);
                }
            }
        } else {
            toast("Выберите объектов", { type: "error" });
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <SelectAlbumModal musicIds={ids} setMusicIds={setIds} isOpen={isOpenAlbumModal} setIsOpen={setIsOpenAlbumModal} fetchData={fetchModels} />
            <SelectCategoryModal musicIds={ids} setMusicIds={setIds} isOpen={isOpenCategoryModal} setIsOpen={setIsOpenCategoryModal} fetchData={fetchModels} />
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <div className="d-flex align-items-center">
                            {isStash ? (
                                <>
                                    <a role={"button"} className="text-white me-3" onClick={() => setIsStash(false)}>{album ? album.name : title}</a>

                                    <h3 className="title-1 mt-0 mb-0 d-flex">
                                        <span className="me-3 text-danger">
                                            Корзина
                                        </span>
                                    </h3>
                                </>
                            ) : (
                                <>
                                    <h3 className="title-1 mt-0 mb-0 d-flex">
                                        <span className="me-3">
                                            {album ? album.name : title}
                                        </span>
                                    </h3>
                                    <a role={"button"} className="text-danger" onClick={() => setIsStash(true)}>Корзина</a>
                                </>
                            )}

                        </div>

                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                {!isStash ? (
                                    <NavLink to={`/${controllerName}/create${album ? `/${album.id}/${album.author_id}` : ``}`} className="show-all btn btn-success fs-12">
                                        <span>
                                            Добавить
                                        </span>
                                        <i className="fa fa-plus"></i>
                                    </NavLink>
                                ) : (
                                    <a role="button" onClick={deleteAll} className={"show-all btn fs-12 " + (ids.length == 0 ? 'btn-secondary' : 'btn-danger')}>
                                        <span>
                                            {"Удалить"}
                                        </span>
                                        <i className="fa fa-trash"></i>
                                    </a>
                                )}
                                <a role="button" onClick={() => ids.length > 0 && update()} className={"show-all btn btn-sm fs-12 ms-2 " + (ids.length == 0 ? 'btn-secondary' : 'btn-info')}>
                                    <span>
                                        Редактировать
                                    </span>
                                    <i className="fa fa-pencil"></i>
                                </a>
                                <a role="button" onClick={() => ids.length > 0 && stash()} className={"show-all btn btn-sm fs-12 ms-2 " + (ids.length == 0 ? 'btn-secondary' : 'btn-success')}>
                                    <span>
                                        {!isStash ? "В корзину" : "Восстановить из корзины"}
                                    </span>
                                    <i className="fa fa-trash"></i>
                                </a>
                                <a role="button" onClick={() => ids.length > 0 && setIsOpenAlbumModal(true)} className={"show-all btn btn-sm fs-12 ms-2 " + (ids.length == 0 ? 'btn-secondary' : 'btn-primary')}>
                                    <span>
                                        Добавить в альбом
                                    </span>
                                    <i className="fa fa-bars"></i>
                                </a>
                                <a role="button" onClick={() => ids.length > 0 && setIsOpenCategoryModal(true)} className={"show-all btn btn-sm fs-12 ms-2 " + (ids.length == 0 ? 'btn-secondary' : 'btn-warning')}>
                                    <span>
                                        Добавить в категорию
                                    </span>
                                    <i className="fa fa-bars"></i>
                                </a>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <input type="text" className="form-control form-control-sm mx-3" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <Select
                                    className="w-100 my-select-2"
                                    placeholder=" - Артист - "
                                    options={authContext.personState.sort((a, b) => a.label.localeCompare(b.label))}
                                    isSearchable
                                    isClearable
                                    value={author_id}
                                    onChange={(e) => setAuthor_id(e)} />

                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>
                        {play && <div className="row justify-content-center mb-4 d-none">
                            <div className="col-md-6 col-lg-6">
                                <audio ref={audioRef} controls className="mt-4 w-100">
                                    {play.filename_mp3 && <source src={play.filename_mp3} type="audio/mpeg" />}
                                    {play.filename_ogg && <source src={play.filename_ogg} type="audio/ogg" />}
                                </audio>
                            </div>
                            <div className="col-1">
                                <a role="button" className="text-white" onClick={() => setPlay(null)}>
                                    <i className="fa fa-close"></i>
                                </a>
                            </div>
                        </div>}
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "30px" }}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input" id="checkbox"
                                                        checked={ids.length == models.length}
                                                        onClick={(e) => {
                                                            if (ids.length != models.length) {
                                                                setIds(models.map(model => model.id));
                                                            } else {
                                                                setIds([]);
                                                            }
                                                        }}
                                                        onChange={(e) => { }}
                                                    />
                                                    <label className="form-check-label" htmlFor="checkbox"></label>
                                                </div>
                                            </th>
                                            <th style={{ width: "40px" }}></th>
                                            <th className="text-start"><a role="button" onClick={() => {
                                                setSort("name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название {sort == "name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("full_name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Исполнитель {sort == "full_name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("album_id");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Альбомы {sort == "album_id" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("release_date");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата релиза {sort == "release_date" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("created_at");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата добавления {sort == "created_at" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}
                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td>
                                                        <CheckBox ids={ids} setIds={setIds} models={models} model={model} index={index} />
                                                    </td>
                                                    <td className="text-end">
                                                        <a href={model.image} target="_blank" className="text-white">
                                                            <img className="border-radius-2" src={model.image} alt="" style={{ width: "40px" }} />
                                                        </a>
                                                    </td>
                                                    <td className="text-start">
                                                        <div className="d-flex align-items-center">
                                                            <NavLink to={`/${controllerName}/` + model.id} className="text-white">{model.name} {model.version && <small className="text-white-50">{model.version}</small>}</NavLink>
                                                        </div>
                                                        <div>
                                                            {model.release_date > currentDateTime() && <span className="ms-3 badge text-success d-block">[Запланировано]</span>}
                                                            {model.status == 12 && <span className="ms-3 badge text-warning d-block">[Модерация]</span>}
                                                            {model.status == 15 && <span className="ms-3 badge text-danger d-block">[Отклонено]</span>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <NavLink to={"/settings/person/" + model.author_id} className="text-white">{model.author.name}</NavLink>{authContext.personState.filter((singer) => model.authors.indexOf(singer.value) > -1).map((person, index) => {
                                                            return <NavLink key={index} to={"/settings/person/" + person.value} className="text-white">{", " + person.model.name}</NavLink>
                                                        })}
                                                    </td>
                                                    <td>
                                                        {model.albumModels.map((album, index) => <NavLink key={index} to={"/album/" + album.id} className="text-white me-2">{album.name}{index != model.albumModels.length - 1 ? ", " : ""}</NavLink>)}
                                                    </td>
                                                    <td>
                                                        {model.release_date && model.release_date.split(" ")[0].split("-").reverse().join(".")}
                                                    </td>
                                                    <td>
                                                        {model.created_at && model.created_at.split(" ")[0].split("-").reverse().join(".")}
                                                    </td>
                                                    <td>
                                                        <a role="button" className="text-white me-3" onClick={() => {
                                                            if (play?.id == model.id)
                                                                setPlay(null);
                                                            else
                                                                setPlay(model);
                                                        }}>
                                                            {play?.id == model.id ? <i className="fa fs-18 fa-stop"></i> : <i className="fa fs-18 fa-play"></i>}
                                                        </a>
                                                        <NavLink to={`/${controllerName}/` + model.id} className="text-white me-3">
                                                            <i className="fa fs-18 fa-pencil"></i>
                                                        </NavLink>
                                                        <a role="button" className="text-white me-3" onClick={() => {
                                                            if (isStash)
                                                                handleDelete(model.id);
                                                            else
                                                                stash([model.id]);
                                                        }}>
                                                            <i className="fa fs-18 fa-trash"></i>
                                                        </a>
                                                        <a className="text-white" target="_blank" href={`https://rizanova.uz/music/${model.id}`}>
                                                            <i className="fa fa-link fs-18"></i>
                                                        </a>
                                                        <Tooltip content={(
                                                            <div>
                                                                <p className='mb-0'>{model.name}</p>
                                                                <p className='mb-0'>{"Добавил: " + model.admins?.created_by + " в " + model.admins?.created_at}</p>
                                                                {model.admins?.updated_at != model.admins?.created_at && model.admins?.updated_at && <p className='mb-0'>{"Обновлен: " + model.admins.updated_by + " в " + model.admins?.updated_at}</p>}
                                                            </div>
                                                        )} className='target d-inline'>
                                                            <a role="button" className='ms-4 text-white'>
                                                                <i className="fa fa-info-circle"></i>
                                                            </a>
                                                        </Tooltip>
                                                    </td>
                                                </tr>

                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                            marginPagesDisplayed={7}
                            pageRangeDisplayed={5}
                            onPageChange={(p) => setPage(p.selected + 1)}
                            forcePage={parseInt(page - 1)}
                            containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                            activeClassName={'active'}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />}

                    </div>
                </div>
            </div>

        </section>
    );
}