import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { get } from '../../utils/request';
import axios from 'axios';
import { currentDateTime, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { AuthContext } from '../../components/context';
import Select from 'react-select';
import ReactHlsPlayer from 'react-hls-player';
import Tooltip from 'react-tooltip-lite';

const controllerName = "clip";
const title = "Клипы";

const CancelToken = axios.CancelToken;
let cancel;

export default function Clip() {
    const authContext = React.useContext(AuthContext);

    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("release_date");
    const [sortType, setSortType] = useState("desc");
    const [author_id, setAuthor_id] = useState("");
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);

    const [play, setPlay] = useState(null);
    const [album, setAlbum] = useState(null);
    const audioRef = useRef();

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${author_id ? `&author_id=${author_id.value}` : ``}${sortType ? `&sort_type=${sortType}` : ``}${page ? `&page=${page}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.models);
                setAlbum(res.album);
                setPagination(res.pagination)
            }

            setIsLoading(false);
        }
    }

    React.useEffect(async () => {
        document.title = title;

        await fetchModels();
    }, [search, sort, sortType, page, limit, author_id]);

    React.useEffect(async () => {
        if (audioRef.current)
            audioRef.current.play();
    }, [play]);

    const handleDelete = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setPlay(null);

                    setModels(models.filter((model) => model.id !== id));
                    toast.success(res.message);
                }
            }
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {album ? album.name : title}
                                    </span>
                                </h3>
                                <NavLink to={`/${controllerName}/create${album ? `/${album.id}/${album.author_id}` : ``}`} className="show-all btn btn-success fs-12">
                                    <span>
                                        Добавить
                                    </span>
                                    <i className="fa fa-plus"></i>
                                </NavLink>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <Select
                                    className="w-100 my-select-2"
                                    placeholder=" - Артист - "
                                    options={authContext.personState.sort((a, b) => a.label.localeCompare(b.label))}
                                    isSearchable
                                    isClearable
                                    value={author_id}
                                    onChange={(e) => setAuthor_id(e)} />

                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>
                        {play && <div style={{ position: 'fixed', right: 25, top: 15, zIndex: 3000, width: 300 }}><div className="row justify-content-center mb-4">
                            <div className="col-md-11 col-lg-11">
                                <ReactHlsPlayer
                                    playerRef={audioRef}
                                    className="mt-4 w-100"
                                    src={play.filenames}
                                    autoPlay={false}
                                    controls={true}
                                    width="100%"
                                    height="auto"
                                    ref={audioRef}
                                />
                            </div>
                            <div className="col-1">
                                <a role="button" onClick={() => setPlay(null)}>
                                    <i className="fa fa-close text-white"></i>
                                </a>
                            </div>
                        </div></div>}
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "80px" }}></th>
                                            <th className="text-start"><a role="button" onClick={() => {
                                                setSort("name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название {sort == "name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("full_name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Исполнитель {sort == "full_name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("release_date");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата релиза {sort == "release_date" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("created_at");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата добавления {sort == "created_at" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}
                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td className="text-end">
                                                        <a href={model.image} target="_blank" className="text-white">
                                                            <img className="border-radius-2" src={model.image} alt="" style={{ width: "80px" }} />
                                                        </a>
                                                    </td>
                                                    <td className="text-start">
                                                        <div className="d-flex align-items-center">
                                                            <NavLink to={`/${controllerName}/` + model.id} className="text-white">{model.name} {model.version && <small className="text-white-50">{model.version}</small>}</NavLink>
                                                        </div>

                                                        <div>
                                                            {model.release_date > currentDateTime() && <span className="ms-3 badge text-success d-block">[Запланировано]</span>}
                                                            {model.status == 12 && <span className="ms-3 badge text-warning d-block">[Модерация]</span>}
                                                            {model.status == 15 && <span className="ms-3 badge text-danger d-block">[Отклонено]</span>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {authContext.personState.filter((singer) => model.authors.indexOf(singer.value) > -1).map((person, index) => {
                                                            return <NavLink key={index} to={"/settings/person/" + person.value} className="text-white">{person.model.name + (index != model.authors.length - 1 ? ', ' : '')}</NavLink>
                                                        })}
                                                    </td>
                                                    <td>
                                                        {model.release_date && model.release_date.split(" ")[0].split("-").reverse().join(".")}
                                                    </td>
                                                    <td>
                                                        {model.created_at && model.created_at.split(" ")[0].split("-").reverse().join(".")}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <a role="button" className="text-white me-3" onClick={() => {
                                                                setPlay(model);
                                                            }}>
                                                                <i className="fa fs-18 fa-play"></i>
                                                            </a>
                                                            <NavLink to={`/${controllerName}/` + model.id} className="text-white me-3">
                                                                <i className="fa fs-18 fa-pencil"></i>
                                                            </NavLink>
                                                            <a role="button" className="text-white me-3" onClick={() => {
                                                                handleDelete(model.id);
                                                            }}>
                                                                <i className="fa fs-18 fa-trash"></i>
                                                            </a>
                                                            <a className="text-white" target="_blank" href={`https://rizanova.uz/clip/${model.id}`}>
                                                                <i className="fa fa-link fs-18"></i>
                                                            </a>
                                                            <Tooltip content={(
                                                                <div>
                                                                    <p className='mb-0'>{model.name}</p>
                                                                    <p className='mb-0'>{"Добавил: " + model.admins?.created_by + " в " + model.admins?.created_at}</p>
                                                                    {model.admins?.updated_at != model.admins?.created_at && model.admins?.updated_at && <p className='mb-0'>{"Обновлен: " + model.admins.updated_by + " в " + model.admins?.updated_at}</p>}
                                                                </div>
                                                            )} className='target d-inline'>
                                                                <a role="button" className='ms-4 text-white'>
                                                                    <i className="fa fa-info-circle"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                            marginPagesDisplayed={7}
                            pageRangeDisplayed={5}
                            onPageChange={(p) => setPage(p.selected + 1)}
                            forcePage={parseInt(page - 1)}
                            containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                            activeClassName={'active'}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />}

                    </div>
                </div>
            </div>
        </section>
    );
}