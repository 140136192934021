import React, { useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from '../../components/context';
import { get, post } from '../../utils/request';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

const controllerName = "transaction";
const title = "Чеки";

export default function TransactionHandle() {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);

    const isMounted = useIsMounted();

    const { id } = useParams();
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }
    }, []);

    React.useEffect(() => {
        if (model) {
            reset({
                full_name: model.full_name,
                payer: model.payer,
                composition: model.composition,
                comment: model.comment,
                partner_id: model.partner ? {
                    label: model.partner.full_name + ` — ID: ${model.partner._id} — Tel: ${model.partner.username}`, value: model.partner.id,
                    value: model.partner.id
                } : null,
                currency: model.currency
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            partner_id: data.partner_id ? (typeof data.partner_id == 'object' ? data.partner_id.value : data.partner_id) : '',
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    history.push(`/${controllerName}/` + res.model.id)
                else setModel(res.model);

                toast.success(res.message);
            }

            authContext.loadCountries();
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadPartners = async (inputValue, callback) => {
        if (callback) {
            let res = await get(`partner/index?search=${inputValue}&limit=100&sort=full_name`);

            let models = res.models.map(model => ({ label: model.full_name + ` — ID: ${model._id} — Tel: ${model.username}`, value: model.id }));

            callback(models);
            return models;
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6 flex-wrap">
                                <h3 className="title-1 mt-0 mb-0 d-flex w-100">
                                    <span className="me-3">
                                        {title} {!!model && (" — " + model.full_name + " - " + model.composition)}
                                    </span>
                                </h3>
                                <NavLink to="/transaction" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Партнер *:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name="partner_id"
                                            defaultOptions
                                            render={({ field }) => (
                                                <AsyncSelect
                                                    {...field}
                                                    className={(errors.partner_id?.type)}
                                                    defaultOptions
                                                    placeholder=" - Выберите партнера - "
                                                    loadOptions={loadPartners}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Псевдоним *:</p>
                                        <input {...register("full_name", { required: true })} className={"formColOneRowFlexInput " + (errors.full_name?.type)} type="text" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Композиция *:</p>
                                        <input {...register("composition", { required: true })} className={"formColOneRowFlexInput " + (errors.composition?.type)} type="text" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Плательщик *:</p>
                                        <input {...register("payer", { required: true })} className={"formColOneRowFlexInput " + (errors.payer?.type)} type="text" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Категория *:</p>
                                        <input list='categories' {...register("comment", { required: true })} className={"formColOneRowFlexInput " + (errors.comment?.type)} type="text" />
                                        <datalist id="categories">
                                            <option>Категория 2</option>
                                            <option>Категория 3</option>
                                        </datalist>
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Сумма *:</p>
                                        <input list='currencies' {...register("currency", { required: true })} className={"formColOneRowFlexInput " + (errors.currency?.type)} type="number" />
                                        <datalist id="currencies">
                                            <option>200000</option>
                                            <option>500000</option>
                                            <option>1000000</option>
                                        </datalist>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}