import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FILE_HOST_URL, SERVICE_HOST_URL } from '../../../config';

const title = "UzRAP Рендер аудио";

export default function UzRapRenderHandle() {
    const [isLoading, setIsLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState(false);
    const [previewMusic, setPreviewMusic] = useState(false);
    const [progress, setProgress] = useState(0);

    const isMounted = useIsMounted();

    const defaultValues = {
        image: null,
        music: null,
    };

    const { register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {
        document.title = title;
    }, []);

    const onSubmit = async (data) => {
        if (isLoading) return;

        if (data.image.length > 0 && data.music.length > 0) {
            setIsLoading(true);

            const formData = new FormData();

            formData.append('image', data.image[0]);
            formData.append('music', data.music[0]);

            let res = await axios.post(`${SERVICE_HOST_URL}:3012/upload?lang=${data.lang}`, formData, {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100)
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(res);

            if (res && res.data && res.data.status === "success") {
                reset(defaultValues);
                setPreviewImage(false);
                setPreviewMusic(false);
                toast.success("Успешно загружено", { toastId: "render", updateId: "render" });
            }

            setIsLoading(false);
        } else {

        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to="/settings" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center mb-3">
                            <div className="col-md-2">
                                {previewImage && <img className="w-100 d-block" src={previewImage} />}
                            </div>
                            <div className="col-md-6 d-flex align-items-center">
                                {previewMusic && <audio className="w-100" controls src={previewMusic}></audio>}
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Музыка:</p>
                                        <input {...register("music", { required: true })} type="file" accept="music/*" className={"formColOneRowFlexInput " + (errors.music?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                var reader = new FileReader();

                                                reader.readAsDataURL(file);
                                                reader.onloadend = function (e) {
                                                    setPreviewMusic(reader.result)
                                                }
                                            }
                                        }} />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Картинка:</p>
                                        <input {...register("image", { required: true })} type="file" accept="image/*" className={"formColOneRowFlexInput " + (errors.image?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                var reader = new FileReader();

                                                reader.readAsDataURL(file);
                                                reader.onloadend = function (e) {
                                                    setPreviewImage(reader.result)
                                                }
                                            }
                                        }} />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Язык:</p>
                                        <select {...register("lang", { required: true })} className={"formColOneRowFlexInput " + (errors.lang?.type)}>
                                            <option value="uz">O'zbekcha</option>
                                            <option value="ru">Русский</option>
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Загрузить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}