import React from 'react';

export default function CheckBox({ ids, setIds, models, model, index }) {
    return (
        <div className="form-check">
            <input
                type="checkbox"
                className="form-check-input" id={"checkbox" + index}
                checked={
                    !!ids.find(
                        (id) =>
                            id ==
                            model.id
                    )
                }
                onClick={(e) => {
                    let lastIndex = models.findIndex((model) => model.id == ids[ids.length - 1]);

                    if (
                        e.target.checked
                    ) {
                        if (e.shiftKey && lastIndex > -1) {
                            setIds([
                                ...ids,
                                ...models.filter((model, ii) => lastIndex < index ? ii >= lastIndex && ii <= index : ii >= index && ii <= lastIndex).map((model) => model.id)
                            ]);
                        } else {
                            setIds([
                                ...ids,
                                model.id,
                            ]);
                        }
                    } else {
                        setIds(ids.filter(
                            (
                                id
                            ) =>
                                id !=
                                model.id
                        ));
                    }
                }}
                onChange={(e) => {

                }}
            />
            <label className="form-check-label" htmlFor={"checkbox" + index}>

            </label>
        </div>

    );
}