import React, { useRef, useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { get, post } from '../../utils/request';
import axios from 'axios';
import { arrayToFormData, currentDateTime, typeLink, typeName, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { AuthContext } from '../../components/context';
import Select from 'react-select';
import CheckBox from '../../components/forms/CheckBox';
import SelectAlbumModal from '../../components/SelectAlbumModal';

import Tooltip from 'react-tooltip-lite';

const controllerName = "template";
const title = "Черновик";

const CancelToken = axios.CancelToken;
let cancel;

export default function Template() {
    const authContext = React.useContext(AuthContext);

    let history = useHistory();

    const [models, setModels] = useState([]);
    const [episodes, setEpisodes] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [ids, setIds] = useState([]);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("created_at");
    const [sortType, setSortType] = useState("desc");
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${sortType ? `&sort_type=${sortType}` : ``}${page ? `&page=${page}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.videos);
                setEpisodes(res.episodes);
            }

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        fetchModels();
    }, [search, sort, sortType, page, limit]);

    const deleteVideo = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete-video?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setModels(models.filter((model) => model.id !== id));
                    toast.success(res.message);
                }
            }
        }
    }

    const deleteEpisode = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete-episode?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setEpisodes(episodes.filter((model) => model.id !== id));
                    toast.success(res.message);
                }
            }
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <nav className="title-nav mb-2 flex-wrap">
                                    <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-4">
                                        <h3 className="title-1 mt-0 mb-0 d-flex">
                                            <span className="me-3">
                                                {title}
                                            </span>
                                        </h3>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center mb-3 col-12 col-md-4">
                                        <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end mb-3 col-12 col-md-4">
                                        <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                            <option value="20"> 20 </option>
                                            <option value="50"> 50 </option>
                                            <option value="100"> 100 </option>
                                            <option value="200"> 200 </option>
                                            <option value="500"> 500 </option>
                                        </select>
                                    </div>
                                </nav>

                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th><a role="button" onClick={() => {
                                                setSort("type");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Тип {sort == "type" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название {sort == "name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th><a role="button" onClick={() => {
                                                setSort("duration");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Хронометраж {sort == "duration" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length + episodes.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}
                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td>
                                                        {typeName(model.type)}
                                                    </td>
                                                    <td>
                                                        {model.name}
                                                    </td>

                                                    <td>
                                                        {model.duration}
                                                    </td>

                                                    <td>
                                                        <NavLink to={`/${typeLink(model.type)}/${model.id}`} className="text-white me-3">
                                                            <i className="fa fs-18 fa-edit"></i>
                                                        </NavLink>
                                                        <a role="button" onClick={() => deleteVideo(model.id)} className="text-white">
                                                            <i className="fa fs-18 fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {episodes.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td>
                                                        Эпизод
                                                    </td>
                                                    <td>
                                                        {model.name}
                                                    </td>
                                                    <td>
                                                        {model.duration}
                                                    </td>
                                                    <td>
                                                        <NavLink to={`/episode/${model.id}`} className="text-white me-3">
                                                            <i className="fa fa-edit"></i>
                                                        </NavLink>
                                                        <a role="button" onClick={() => deleteEpisode(model.id)} className="text-white">
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}