import React from 'react';

export const tryParseJSON = jsonString => {
    try {
        var o = JSON.parse(jsonString);

        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) { }

    return jsonString;
};

export function number_format(
    number,
    decimals = 0,
    dec_point = '.',
    thousands_sep = ' ',
) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const arrayToFormData = (data) => {
    let formData = new FormData();

    for (let prop in data) {
        if (Array.isArray(data[prop])) {
            for (let index in data[prop]) {
                if (
                    !(data[prop][index] instanceof File) &&
                    typeof data[prop][index] === 'object'
                ) {
                    for (let p in data[prop][index]) {
                        formData.append(
                            prop + '[' + index + '][' + p + ']',
                            data[prop][index][p],
                        );
                    }
                } else {
                    formData.append(prop + '[]', data[prop][index]);
                }
            }
        } else {
            formData.append(prop, data[prop]);
        }
    }

    return formData;
};

export function useIsMounted() {

    return true;

    const ref = React.useRef(true);

    React.useEffect(() => {
        return () => {
            ref.current = false;
        };
    }, []);

    return React.useCallback(() => ref.current, []);
}

export function currentDateTime() {
    var date = new Date();
    var dateStr =
        date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2) + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);

    return dateStr;
}

export function toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
    }
}

export function typeName(type) {
    switch (type) {
        case 1: return "Клип";
        case 2: return "Фильм";
        case 3: return "Сериал";
        case 4: return "Концерт";
        case 5: return "Юмор";
        case 6: return "Теленовелла";
        default: return "(неизвестный тип)";
    }
}

export function typeLink(type) {
    switch (type) {
        case 1: return "clip";
        case 2: return "film";
        case 3: return "episode";
        case 4: return "concert";
        case 5: return "humor";
        case 6: return "telenovella";
        default: return "unkown";
    }
}