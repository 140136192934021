import React, { useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../components/context';
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "country";
const title = "Страны";

export default function CountryHandle() {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);

    const isMounted = useIsMounted();

    const { id } = useParams();
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }
    }, []);

    React.useEffect(() => {
        if (model) {
            reset(model);
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            sort: data.sort ? data.sort : 0
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    history.push(`/settings/${controllerName}/` + res.model.id)
                else setModel(res.model);

                toast.success(res.message);
            }

            authContext.loadCountries();
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title} {!!model && " — " + model.name_uz}
                                    </span>

                                </h3>
                                <NavLink to="/settings/country" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Название UZ *:</p>
                                        <input {...register("name_uz", { required: true })} className={"formColOneRowFlexInput " + (errors.name_uz?.type)} type="text" placeholder="Введите название на узбекском" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Название RU *:</p>
                                        <input {...register("name_ru", { required: true })} className={"formColOneRowFlexInput " + (errors.name_ru?.type)} type="text" placeholder="Введите название на русском" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Приоритет:</p>
                                        <input {...register("sort", { required: false })} className={"formColOneRowFlexInput " + (errors.sort?.type)} type="number" placeholder="Введите значение" />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}