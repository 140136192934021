import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get } from '../../utils/request';
import { useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "report";
const title = "Геренация отчета";

export default function ReportHandle() {
    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useIsMounted();

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({
        defaultValues: {
            year: "",
            month: "",
            dopdoxod: 0,
            type: ""
        }
    });

    const type = watch('type');

    console.log(type);

    React.useEffect(() => {
        document.title = title;
    }, []);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await get(`${controllerName}/generate?year=${data.year}&month=${data.month}&dopdoxod=${data.dopdoxod}&type=${data.type}`);

        if (res?.status === "success") {
            if (isMounted) {
                toast.success(res.message);

                reset({
                    year: "",
                    month: "",
                    dopdoxod: 0,
                    type: ""
                });
            }
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const years = () => {
        let y = [];

        for (let i = 2022; i <= (new Date()).getFullYear(); i++) {
            y.push(<option value={i}>{i}</option>);
        }

        return y;
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to={"/" + controllerName} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Тип *:</p>
                                        <select {...register("type", { required: true })} className={"formColOneRowFlexInput " + (errors.type?.type)} type="text">
                                            <option value=""> - Выберите тип - </option>
                                            <option value="1">Отчет по сумме подписок</option>
                                            <option value="2">Отчет по доходе от рекламы</option>
                                        </select>
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Год *:</p>
                                        <select {...register("year", { required: true })} className={"formColOneRowFlexInput " + (errors.year?.type)} type="text">
                                            <option value=""> - Выберите год - </option>
                                            {years()}
                                        </select>
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Месяц *:</p>
                                        <select {...register("month", { required: true })} className={"formColOneRowFlexInput " + (errors.month?.type)} type="text" placeholder="Введите месяц (MM)" >
                                            <option value=""> - Выберите месяц - </option>
                                            <option value="01">Январь</option>
                                            <option value="02">Февраль</option>
                                            <option value="03">Март</option>
                                            <option value="04">Апрель</option>
                                            <option value="05">Май</option>
                                            <option value="06">Июнь</option>
                                            <option value="07">Июль</option>
                                            <option value="08">Август</option>
                                            <option value="09">Сентябрь</option>
                                            <option value="10">Октябрь</option>
                                            <option value="11">Ноябрь</option>
                                            <option value="12">Декабрь</option>
                                        </select>
                                    </div>
                                    <div className={`formColRowFlexDiv ${type != 2 ? 'd-none' : ''}`}>
                                        <p className="formColOneRowFlexLabel">Доход от рекламы *:</p>
                                        <input {...register("dopdoxod", { required: type == 2 })} className={"formColOneRowFlexInput " + (errors.dopdoxod?.type)} type="number" />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}