import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../components/context';
import Sidebar from '../../../components/Settings/Sidebar';
import { useIsMounted } from '../../../utils/functions';
import { get } from '../../../utils/request';

const controllerName = "genre";
const title = "Жанры";

export default function Genres() {
    const authContext = React.useContext(AuthContext);

    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useIsMounted();
    const [sortType, setSortType] = useState("asc");
    const [sort, setSort] = useState("");
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    React.useEffect(() => {
        const fetchModels = async () => {
            document.title = title;

            setIsLoading(true);

            let res = await get(`${controllerName}/index?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${page ? `&page=${page}` : ``}${sortType ? `&sort_type=${sortType}` : ``}`);

            if (isMounted) {
                if (res) {
                    setPagination(res.pagination);
                    setModels(res.models);
                }

                setIsLoading(false);
            }
        }

        fetchModels();
    }, [search, sort, page, limit, sortType]);

    const handleDelete = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                setModels(models.filter((model) => model.id !== id));
                toast.success(res.message);

                authContext.loadGenres();
            }

        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end justify-content-between mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        Настройки
                                    </span>
                                </h3>
                                <NavLink to={`/settings/${controllerName}/create`} className="show-all btn btn-success fs-12">
                                    <span>
                                        Добавить
                                    </span>
                                    <i className="fa fa-plus"></i>
                                </NavLink>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3 justify-content-center">
                                <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3 justify-content-end">
                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="10"> 10 </option>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-md-3">
                                <Sidebar />
                            </div>
                            <div className="col-md-9">
                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "40px" }}></th>
                                            <th className="text-start"><a role="button" onClick={() => {
                                                setSort("name_ru");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название RU {sort == "name_ru" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th className="text-start"><a role="button" onClick={() => {
                                                setSort("name_uz");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название UZ {sort == "name_uz" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}
                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td className="text-end">
                                                        <a href={model.image} target="_blank" className="text-white">
                                                            <img className="border-radius-2" src={model.image} alt="" style={{ width: "40px" }} />
                                                        </a>
                                                    </td>
                                                    <td className="text-start"><NavLink to={`/settings/${controllerName}/` + model.id} className="text-white">{model.name_ru}</NavLink></td>
                                                    <td className="text-start"><NavLink to={`/settings/${controllerName}/` + model.id} className="text-white">{model.name_uz}</NavLink></td>

                                                    <td>
                                                        <NavLink to={`/settings/${controllerName}/` + model.id} className="text-white me-3">
                                                            <i className="fa fs-18 fa-pencil"></i>
                                                        </NavLink>
                                                        <a role="button" className="text-white" onClick={() => {
                                                            handleDelete(model.id);
                                                        }}>
                                                            <i className="fa fs-18 fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>

                                            );
                                        })}
                                    </tbody>
                                </table>

                                {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                                    marginPagesDisplayed={7}
                                    pageRangeDisplayed={5}
                                    onPageChange={(p) => setPage(p.selected + 1)}
                                    forcePage={parseInt(page - 1)}
                                    containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                                    activeClassName={'active'}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    nextClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextLinkClassName={"page-link"}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}