import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/context';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import UploadMusic from '../../components/UploadMusic';
import ExistingMusic from '../../components/ExistingMusic';
import { FILE_HOST_URL, PROFA_ARTIST, PROFA_AVTOR_TEKSTA, PROFA_KOMPOZITOR, PROFA_LEYBL, PROFA_PRODYUSER } from '../../config';
import SelectExistingMusic from '../../components/SelectExistingMusic';
import AsyncCreatableSelect from '../../components/AsyncCreatableSelect';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment/moment';

const DragHandle = sortableHandle(() => <span className="sort-icon cursor-row-resize"></span>);

const controllerName = "album";
const title = "Альбомы";

let statuses = [
    { value: "1", label: 'Доступен всем' },
    { value: "0", label: 'Ограничить доступ' },
    { value: "2", label: 'Доступ по ссылке' },
    { value: "12", label: 'Модерация' },
    { value: "15", label: 'Отклонен' },
];

export default function AlbumHandle() {
    const authContext = React.useContext(AuthContext);

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [isOpenSelectExistingMusic, setIsOpenSelectExistingMusic] = useState(false);

    const isMounted = useIsMounted();

    const { id } = useParams();

    const imageRef = useRef();
    let [previewImage, setPreviewImage] = useState(null);
    let [previewBackground, setPreviewBackground] = useState(null);

    const [musics, setMusics] = useState([]);
    const [sortable, setSortable] = useState([]);
    const [sortChanged, setSortChanged] = useState(false);

    const defaultValues = {
        name: "",
        version: "",
        release_date: moment().format("YYYY-MM-DD"),
        author_id: "",
        status: statuses[0],
        is_premiere: false,
        producers: [],
        leybls: [],
        author_texts: [],
        compositors: [],
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, trigger } = useForm({
        defaultValues
    });

    const fetchData = async () => {
        setIsLoading(true);

        let res = await get(`${controllerName}/get?id=${id}`);

        if (isMounted) {
            setModel(res);
            setSortable(res.musics.filter(music => music.status != 3));

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        if (id) {
            fetchData();
        }
    }, [id]);

    React.useEffect(() => {
        if (model) {
            reset({
                name: model.name,
                version: model.version,
                release_date: model.release_date,
                is_premiere: model.is_premiere,

                author_id: authContext.personState.find((singer) => singer.value === model.author_id),
                status: statuses.find((status) => status.value == model.status),
                compositors: [...authContext.personState.filter((singer) => model.compositors.indexOf(singer.value) > -1)],
                author_texts: [...authContext.personState.filter((singer) => model.author_texts.indexOf(singer.value) > -1)],
                leybls: [...authContext.personState.filter((singer) => model.leybls.indexOf(singer.value) > -1)],
                producers: [...authContext.personState.filter((singer) => model.producers.indexOf(singer.value) > -1)],
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : '',
            producers: data.producers.map((producer) => producer.value),
            status: data.status ? data.status.value : 1,
            is_premiere: data.is_premiere ? 1 : 0,
            compositors: data.compositors.map((compositor) => compositor.value),
            author_texts: data.author_texts.map((author_text) => author_text.value),
            producers: data.producers.map((producer) => producer.value),
            leybls: data.leybls.map((leybl) => leybl.value),
            author_id: data.author_id ? data.author_id.value : ""
        }));

        if (res?.status === "success") {
            if (isMounted) {
                window.onbeforeunload = function (e) {
                    return null;
                };

                if (!id) {
                    history.push("/album/" + res.model.id)
                } else {
                    setModel(res.model);
                    setSortable(res.model.musics.filter(music => music.status != 3));
                }

                toast.success(res.message);
                authContext.loadAlbums();
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadPersons = (profa = PROFA_ARTIST) => (inputValue, callback) => {
        if (callback)
            callback(authContext.personState.sort((a, b) => a.label.localeCompare(b.label)).filter(person => person.model.profas.indexOf(profa) > -1).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const [serverBackgroundFile, setServerBackgroundFile] = useState(null);
    const [backgroundProgress, setBackgroundProgress] = useState(0);
    const [isServerBackgroundFileLoading, setIsServerBackgroundFileLoading] = useState(false);
    const [isBackgroundDone, setIsBackgroundDone] = useState(false);

    let serverBackgroundFileInput = useRef();

    React.useEffect(() => {
        if (serverBackgroundFile) {
            uploadBackgroundToServer();
        }
    }, [serverBackgroundFile]);

    const uploadBackgroundToServer = async () => {
        if (serverBackgroundFile) {
            setIsServerBackgroundFileLoading(true);
            setIsBackgroundDone(false);

            let res = await axios.post(`${FILE_HOST_URL}/hot-upload-background.php`, arrayToFormData({
                background: serverBackgroundFile
            }), {
                onUploadProgress: (event) => {
                    setBackgroundProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                setValue("background", res.data.background);

                setPreviewBackground(res.data.background);

                if (serverBackgroundFileInput)
                    serverBackgroundFileInput.current.value = null;
            }

            setIsBackgroundDone(true);
        }
    }

    const deleteThis = (name) => {
        setMusics(oldMusics => oldMusics.filter(music => music.name != name));
    }

    const SortableItem = SortableElement(({ value, id }) => <ExistingMusic music={value} fetchData={fetchData} album_id={id} dragHandle={<DragHandle />} />);

    const SortableList = SortableContainer(({ items, id }) => {
        return (
            <ul className={`list-group list-group-flush ${items.length > 0 ? 'list-group-numbered2' : ''}`}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} id={id} />
                ))}
            </ul>
        );
    });

    useEffect(() => {

        if (sortable.length > 0 && sortChanged) {
            const fd = async () => {
                const map = sortable.map((music) => music.id);

                let res = await post(`album/change-sort`, arrayToFormData({ id, map }));
            }

            fd();
        }
    }, [sortChanged, sortable]);

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-4">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to={`/${controllerName}`} className="show-all btn btn-secondary fs-12 me-3">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                                <NavLink to={`/music/create`} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Добавить музыку
                                    </span>
                                    <i className="fa fa-plus"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className={"uploaded-image-square border-radius-5 " + (errors.file?.type == "required" ? "required" : "")}>
                                            {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a href={model.image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            imageRef.current.click();
                                        }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                                    </div>


                                    <div className="text-center me-3">
                                        <div className="uploaded-image-portrait border-radius-5">
                                            {previewBackground ? <img className="w-100 d-block" src={previewBackground} /> :
                                                (model && model.background ? <img className="w-100 d-block" src={model.background} /> : <p className="text-white w-100 my-0">Заставка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            serverBackgroundFileInput.current.click();
                                        }}>{previewBackground ? "Изменить" : "Загрузить"}</a>
                                    </div>

                                    <div className="w-25">
                                        <div className="formColRowFlexDiv">
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="status"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder="Параметы доступа"
                                                        className="basic-single w-100"
                                                        classNamePrefix="select"
                                                        defaultValue={{ value: "1", label: 'Доступен всем' }}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={statuses}
                                                    />
                                                )}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">

                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <p className="text-white">Данные альбома</p>
                                        <hr />
                                    </div>
                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel fs-14">Ссылка на заставку *:</p>
                                        <input {...register("background", { required: false })} className={"formColOneRowFlexInput fs-14 cursor-pointer " + (errors.background?.type)} type="text" readOnly placeholder="Ссылка на файл заставки" onClick={(e) => {
                                            if (e.target.value) {
                                                window.open(e.target.value, '_blank');
                                            }
                                        }} />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Исполнитель *:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name="author_id"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    className={(errors.author_id?.type)}
                                                    placeholder=" - Выберите исполнителя - "
                                                    profa={PROFA_ARTIST}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Продюсеры:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="producers"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.producers?.type)}
                                                    placeholder=" - Выберите продюсеров - "
                                                    profa={PROFA_PRODYUSER}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Лэйбл:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="leybls"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.leybls?.type)}
                                                    placeholder=" - Выберите лэйблов - "
                                                    profa={PROFA_LEYBL}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Название *:</p>
                                        <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Версия:</p>
                                        <input {...register("version", { required: false })} className={"formColOneRowFlexInput " + (errors.version?.type)} type="text" placeholder="Введите версию" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Дата релиза *:</p>
                                        <input {...register("release_date", { required: true })} className={"formColOneRowFlexInput " + (errors.release_date?.type)} type="date" placeholder="Введите название" />
                                    </div>

                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Картинка:</p>
                                        <input {...register("file", { required: !model })} type="file" accept="image/*" ref={imageRef} className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                setValue("file", file);
                                                trigger('file');
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);

                                                reader.onloadend = function (e) {
                                                    setPreviewImage([reader.result])
                                                }
                                            } else {
                                                setValue("file", null);
                                                trigger('file');
                                                setPreviewImage(null);
                                            }
                                        }} />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>

                                <div className="admin-form mt-3">
                                    <div>
                                        <p className="text-white">Значении по умолчанию для новых музыки</p>
                                        <hr />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Авторы текста:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="author_texts"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.author_texts?.type)}
                                                    placeholder=" - Выберите авторов текста - "
                                                    profa={PROFA_AVTOR_TEKSTA}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Композиторы:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="compositors"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.compositors?.type)}
                                                    placeholder=" - Выберите композиторов - "
                                                    profa={PROFA_KOMPOZITOR}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className={`mb-4 ${isServerBackgroundFileLoading ? 'd-block' : 'd-none'}`}>

                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Загрузка заставки на сервер:</p>
                                        <input ref={serverBackgroundFileInput} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                setServerBackgroundFile(e.target.files[0]);
                                                setBackgroundProgress(0);
                                            }
                                        }} className={"formColOneRowFlexInput " + (errors.server_background_file?.type)} type="file" accept="image/gif" />
                                    </div>

                                    {isServerBackgroundFileLoading && <div>
                                        <p className="text-white">Загрузка заставки</p>
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="progress mb-4">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${backgroundProgress}%` }}></div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                {backgroundProgress == 100 && <i className="fa fa-check text-success"></i>}
                                            </div>
                                        </div>

                                    </div>}

                                    <div className="d-flex justify-content-end d-none">
                                        <button type="button" onClick={() => {
                                        }}>{isServerBackgroundFileLoading ? <i className="fa fa-spinner fa-2x rotating "></i> : "Загрузить"}</button>
                                    </div>
                                </div>

                                <div action="" className="admin-form">
                                    {!model && <p className="text-white">Сохраните альбом чтобы добавить музыки</p>}
                                    {model && <>
                                        <p className="text-white">Добавить новые музыки</p>
                                        <Dropzone onDrop={acceptedFiles => setMusics(oldMusics => [...oldMusics, ...acceptedFiles])}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} accept="audio/*" />
                                                        <p className="text-white text-center border p-3 cursor-pointer">Перетащите файлы или нажмите сюда</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <ul className={`list-group list-group-flush ${musics.length > 0 ? 'list-group-numbered2' : ''}`}>
                                            {musics.length + model.musics.filter(music => music.status == 3).length == 0 && <li className="list-group-item list-group-item-dark">Пока пусто</li>}

                                            {musics.map((music, index) => {
                                                return <UploadMusic wait={index != 0} music={music} key={index} album_id={model.id} singer_id={model.author_id} compositors={model.compositors} author_texts={model.author_texts} fetchData={fetchData} deleteThis={deleteThis} />;
                                            })}

                                            {model.musics.filter(music => music.status == 3).map((music, index) => {
                                                return <ExistingMusic key={index} music={music} fetchData={fetchData} album_id={id} />;
                                            })}

                                            {musics.length == 0 && model.musics.filter(music => music.status == 3).length > 0 && <li className="list-group-item list-group-item-dark py-3">
                                                <a role="button" onClick={() => {
                                                    history.push('/music/album/' + id + "/" + model.musics.filter(music => music.status == 3).map(music => music.id).join(","));
                                                }}>
                                                    <i className="fa fa-pencil"></i> Изменить всех
                                                </a>
                                            </li>}
                                        </ul>
                                    </>}
                                </div>

                                <div className="admin-form mt-4">
                                    {model && <>
                                        <p className="text-white">Существующие музыки</p>

                                        <SortableList useDragHandle items={sortable} id={id} onSortEnd={({ oldIndex, newIndex }) => {
                                            setSortable(arrayMoveImmutable(sortable, oldIndex, newIndex));
                                            setSortChanged(true);
                                        }} />

                                        <ul className={`list-group list-group-flush ${sortable.length > 0 ? 'list-group-numbered2' : ''}`}>
                                            {sortable.length == 0 && <li className="list-group-item list-group-item-dark">Пока пусто</li>}
                                            {musics.length == 0 && sortable.length > 0 && <li className="list-group-item list-group-item-dark py-3">
                                                <a role="button" onClick={() => {
                                                    history.push('/music/album/' + id + "/" + sortable.map(music => music.id).join(","));
                                                }}>
                                                    <i className="fa fa-pencil"></i> Изменить всех
                                                </a>
                                            </li>}
                                        </ul>

                                        <button type="button" className="btn btn-success mt-3" onClick={() => setIsOpenSelectExistingMusic(true)}>Добавить из существующих <i className="fa fa-plus"></i></button>

                                        <SelectExistingMusic isOpen={isOpenSelectExistingMusic} setIsOpen={setIsOpenSelectExistingMusic} author_id={model.author_id} album_id={model.id} fetchAlbum={fetchData} />
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}