import React, { useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "promocode";
const title = "Промокод";

export default function PromocodeHandle() {
    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [tariffs, setTariffs] = useState([]);

    const isMounted = useIsMounted();

    const { id } = useParams();
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        const fetchTariffs = async (id) => {
            setIsLoading(true);

            let res = await get(`subscription/index`);

            if (isMounted) {
                setTariffs(res.models);

                setIsLoading(false);
            }
        }

        fetchTariffs().then(() => {
            if (id) {
                fetchData(id);
            }
        });
    }, []);

    React.useEffect(() => {
        if (model) {
            reset(model);
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    history.push(`/settings/${controllerName}/` + res.model.id)
                else setModel(res.model);

                toast.success(res.message);
            }
        } else {
            if (res.errors) {
                for (const key in res.errors) {
                    if (Object.hasOwnProperty.call(res.errors, key)) {
                        const element = res.errors[key];
                        toast.error(element.join(", "));
                    }
                }
            } else {
                toast.error(res.message);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title} {!!model && " — " + model.name}
                                    </span>
                                </h3>
                                <NavLink to="/settings/promocode" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Название *:</p>
                                        <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Тариф *:</p>
                                        <select {...register("tariff_id", { required: true })} className={"formColOneRowFlexInput " + (errors.tariff_id?.type)}>
                                            <option value=""> - выберите - </option>
                                            {tariffs.filter(tariff => tariff.status == 1).map(tariff => {
                                                return <option value={tariff.id}>{tariff.name} - {tariff.price} ({tariff.month} месяц)</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Код *:</p>
                                        <input {...register("code", { required: true })} className={"formColOneRowFlexInput " + (errors.code?.type)} type="text" placeholder="Введите код" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Срок:</p>
                                        <input {...register("expiry_date", { required: true })} className={"formColOneRowFlexInput " + (errors.expiry_date?.type)} type="date" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Лимит активации:</p>
                                        <input {...register("max_use", { required: true })} className={"formColOneRowFlexInput " + (errors.max_use?.type)} type="number" placeholder="Введите значение" />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}