import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/context';
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request';
import axios from 'axios';
import { FILE_HOST_URL, PROFA_ARTIST, PROFA_AVTOR_TEKSTA, PROFA_KOMPOZITOR, PROFA_LEYBL, PROFA_PRODYUSER } from '../../config';
import AsyncCreatableSelect from '../AsyncCreatableSelect';
import { Collapse } from 'react-bootstrap';
import moment from 'moment';

let statuses = [
    { value: "1", label: 'Доступен всем' },
    { value: "0", label: 'Ограничить доступ' },
    { value: "2", label: 'Доступ по ссылке' },
    { value: "12", label: 'Модерация' },
    { value: "15", label: 'Отклонен' },
];

const controllerName = "music";
const title = "Музыка";

export default function MusicFormInline(props) {
    const authContext = React.useContext(AuthContext);

    let history = useHistory();

    const { album_id, singer_id } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [collapse, setCollapse] = useState(false);

    const isMounted = useIsMounted();

    const { id } = props;

    const imageRef = useRef();

    let [previewImage, setPreviewImage] = useState(null);
    let [previewBackground, setPreviewBackground] = useState(null);
    let [previewMp3, setPreviewMp3] = useState(null);
    let [previewOgg, setPreviewOgg] = useState(null);

    const defaultValues = {
        name: "",
        version: "",
        filename_mp3: "",
        filename_mp3_64: "",
        filename_ogg: "",
        filename_ogg_64: "",
        duration: "",
        release_date: moment().format("YYYY-MM-DD"),
        release_time: "11:00",
        albums: album_id ? [authContext.albumState.find((album) => album.value === album_id)] : [],
        status: statuses[0],
        is_premiere: false,
        // is_hit: false,
        singers: singer_id ? [authContext.personState.find((singer) => singer.value === singer_id)] : [],
        producers: [],
        leybls: [],
        author_texts: [],
        compositors: [],
        categories: [
            {
                value: "8",
                label: "Поп"
            }
        ],
        playlists: [],
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }
    }, []);

    React.useEffect(() => {
        if (model) {
            let dates = model.release_date.split(" ");

            let release_date = dates.length > 0 ? dates[0] : "";
            let release_time = dates.length > 0 ? dates[1] : "";

            reset({
                name: model.name,
                version: model.version,
                filename_mp3: model.filename_mp3,
                filename_mp3_64: model.filename_mp3_64,
                filename_ogg: model.filename_ogg,
                filename_ogg_64: model.filename_ogg_64,
                duration: model.duration,
                is_premiere: model.is_premiere,
                // is_hit: model.is_hit,

                status: model.status == 3 ? statuses[0] : statuses.find((status) => status.value == model.status),
                release_date: release_date,
                release_time: release_time,

                singers: [authContext.personState.find((singer) => singer.value === model.author_id), ...authContext.personState.filter((singer) => model.authors.indexOf(singer.value) > -1)],
                compositors: [...authContext.personState.filter((singer) => model.compositors.indexOf(singer.value) > -1)],
                author_texts: [...authContext.personState.filter((singer) => model.author_texts.indexOf(singer.value) > -1)],
                leybls: [...authContext.personState.filter((singer) => model.leybls.indexOf(singer.value) > -1)],
                producers: [...authContext.personState.filter((singer) => model.producers.indexOf(singer.value) > -1)],
                categories: [...authContext.categoryState.filter((category) => model.categories.indexOf(category.value) > -1)],
                playlists: [...authContext.playlistState.filter((playlist) => model.playlists.indexOf(playlist.value) > -1)],
                albums: [...authContext.albumState.filter((album) => model.albums.indexOf(album.value) > -1)],
            });

        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : '',
            singers: data.singers.map((singer) => singer.value),
            compositors: data.compositors.map((compositor) => compositor.value),
            producers: data.producers.map((producer) => producer.value),
            author_texts: data.author_texts.map((author_text) => author_text.value),
            leybls: data.leybls.map((leybl) => leybl.value),
            categories: data.categories.map((category) => category.value),
            albums: data.albums.map((album) => album.value),
            status: data.status ? data.status.value : 1,
            is_premiere: data.is_premiere ? 1 : 0,
            // is_hit: data.is_hit ? 1 : 0,
            release_date: data.release_time ? data.release_date + " " + data.release_time : data.release_date
        }));

        if (res?.status === "success") {
            if (isMounted) {
                window.onbeforeunload = function (e) {
                    return null;
                };

                if (!id) {
                    if (!props.closeModal)
                        history.push("/music/" + res.model.id)
                    else {
                        setModel(res.model);
                    }

                }
                else setModel(res.model);

                toast.success(res.message);

                props.update(res.model.id);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadCategories = (inputValue, callback) => {
        if (callback)
            callback(authContext.categoryState.sort((a, b) => a.label.localeCompare(b.label)).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const loadPersons = (profa = PROFA_ARTIST) => (inputValue, callback) => {
        if (callback)
            callback(authContext.personState.sort((a, b) => a.label.localeCompare(b.label)).filter(person => person.model.profas.indexOf(profa) > -1).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const loadAlbums = (inputValue, callback) => {
        if (callback)
            callback(authContext.albumState.sort((a, b) => a.label.localeCompare(b.label)).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const [serverFile, setServerFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isServerFileLoading, setIsServerFileLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isError, setIsError] = useState(false);

    let serverFileInput = useRef();

    React.useEffect(() => {
        if (serverFile) {
            uploadToServer();
            window.onbeforeunload = function (e) {
                e = e || window.event;

                if (e) {
                    e.returnValue = 'Закрыть?';
                }

                return 'Закрыть?';
            };
        }
    }, [serverFile]);

    const uploadToServer = async () => {
        if (serverFile) {

            let name = serverFile.name.replace(".mp3", "");

            if (name.indexOf(" - ") > -1) {
                setValue("name", name.split(" - ")[1]);
            } else {
                setValue("name", name);
            }

            setIsServerFileLoading(true);
            setIsDone(false);

            let res = await axios.post(`${FILE_HOST_URL}/hot-upload.php`, arrayToFormData({
                music: serverFile
            }), {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                setValue("filename_mp3", res.data.filename_mp3);
                setValue("filename_mp3_64", res.data.filename_mp3_64);
                setValue("filename_ogg", res.data.filename_ogg);
                setValue("filename_ogg_64", res.data.filename_ogg_64);
                setValue("duration", res.data.duration);

                setPreviewMp3(res.data.filename_mp3);
                setPreviewOgg(res.data.filename_ogg);

                if (serverFileInput)
                    serverFileInput.current.value = null;

                setIsDone(true);
            } else if (res && res.data && res.data.status === "error") {
                setIsError(true);
                toast.error("Произошла ошибка на стороне файлового сервера при загрузке или конвертации файла");
            } else {
                setIsError(true);
                toast.error("Произошла ошибка на стороне файлового сервера, возможно не хватило места на диске");
            }

        }
    }

    const [serverBackgroundFile, setServerBackgroundFile] = useState(null);
    const [backgroundProgress, setBackgroundProgress] = useState(0);
    const [isServerBackgroundFileLoading, setIsServerBackgroundFileLoading] = useState(false);
    const [isBackgroundDone, setIsBackgroundDone] = useState(false);

    let serverBackgroundFileInput = useRef();

    React.useEffect(() => {
        if (serverBackgroundFile) {
            uploadBackgroundToServer();
        }
    }, [serverBackgroundFile]);

    const uploadBackgroundToServer = async () => {
        if (serverBackgroundFile) {
            setIsServerBackgroundFileLoading(true);
            setIsBackgroundDone(false);

            let res = await axios.post(`${FILE_HOST_URL}/hot-upload-background.php`, arrayToFormData({
                background: serverBackgroundFile
            }), {
                onUploadProgress: (event) => {
                    setBackgroundProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                setValue("background", res.data.background);

                setPreviewBackground(res.data.background);

                if (serverBackgroundFileInput)
                    serverBackgroundFileInput.current.value = null;
            }

            setIsBackgroundDone(true);
        }
    }

    return (
        <div className="row">
            <div className="col-lg-4">
                <div className="mb-4 w-100">
                    <div className="row">
                        <div className="col-6">
                            <div className="text-center d-flex flex-wrap justify-content-center">
                                <div className={"uploaded-image-square border-radius-5 " + (errors.file?.type == "required" ? "required" : "")}>
                                    {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a href={model.image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                </div>
                                <a role="button" className="text-white mt-2 d-inline-block w-100" onClick={() => {
                                    imageRef.current.click();
                                }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="text-center d-flex flex-wrap justify-content-center">
                                <div className="uploaded-image-portrait border-radius-5">
                                    {previewBackground ? <img className="w-100 d-block" src={previewBackground} /> :
                                        (model && model.background ? <img className="w-100 d-block" src={model.background} /> : <p className="text-white w-100 my-0">Заставка</p>)}
                                </div>
                                <div className="w-100">
                                    <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                        serverBackgroundFileInput.current.click();
                                    }}>{previewBackground ? "Изменить" : "Загрузить"}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center d-flex justify-content-center flex-wrap me-3 mb-4">
                        <div className={"uploaded-audio-portrait flex-wrap border-radius-5 " + (previewMp3 || model && model.filename_mp3 ? '' : ' bg ') + (errors.filename_mp3?.type)}>
                            {previewMp3 ? <audio className="w-100 d-block" controls>
                                <source src={previewMp3} type="audio/mpeg" />
                                <source src={previewOgg} type="audio/ogg" />
                            </audio> : (model && model.filename_mp3 ? <audio className="w-100 d-block" controls>
                                <source src={model.filename_mp3} type="audio/mpeg" />
                                <source src={model.filename_ogg} type="audio/ogg" />
                            </audio> : <p className="text-white w-100 my-0">MP3</p>)}
                            <div className="w-100">
                                <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                    serverFileInput.current.click();
                                }}>{previewMp3 ? "Изменить" : "Загрузить"}</a>
                            </div>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="formColRowFlexDiv">
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="status"
                                render={({ field }) => {
                                    return (
                                        <Select
                                            {...field}
                                            placeholder="Параметы доступа"
                                            className="basic-single w-100"
                                            classNamePrefix="select"
                                            defaultValue={{ value: "1", label: 'Доступен всем' }}
                                            isClearable={false}
                                            isSearchable={false}
                                            options={statuses}
                                        />
                                    );
                                }}
                            />

                        </div>
                        <div className="formColRowFlexDiv">
                            <input type="checkbox" className="btn-check" id={"is_premiere" + model?.id} autoComplete="off" {...register("is_premiere")} />
                            <label className="btn btn-outline-success w-100 premiere" htmlFor={"is_premiere" + model?.id}>Премьера</label>
                        </div>
                        {model && model.premiere_left && <div className="formColRowFlexDiv text-white">
                            Осталось {model.premiere_left}
                        </div>}
                        {/* <div className="formColRowFlexDiv">
                            <input type="checkbox" className="btn-check btn-check2" id="is_hit" autoComplete="off" {...register("is_hit")} />
                            <label className="btn btn-outline-success w-100 premiere" htmlFor="is_hit">Хит</label>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Дата релиза *:</p>
                        <input {...register("release_date", { required: true })} style={{ width: "150px" }} className={"formColOneRowFlexInput " + (errors.release_date?.type)} type="date" />
                        <input type="time" {...register("release_time", { required: true })} style={{ width: "100px" }} className={"formColOneRowFlexInput " + (errors.release_time?.type)} />
                    </div>


                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Лэйбл:</p>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="leybls"
                            render={({ field }) => (
                                <AsyncCreatableSelect
                                    {...field}
                                    isMulti
                                    className={(errors.leybls?.type)}
                                    placeholder=" - Выберите лэйблов - "
                                    profa={PROFA_LEYBL}
                                />
                            )}
                        />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Альбом:</p>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="albums"
                            render={({ field }) => (
                                <AsyncSelect
                                    {...field}
                                    isMulti
                                    className={(errors.albums?.type)}
                                    defaultOptions
                                    placeholder=" - Выберите альбомы - "
                                    loadOptions={loadAlbums}
                                />
                            )}
                        />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Категории *:</p>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="categories"
                            render={({ field }) => (
                                <AsyncSelect
                                    {...field}
                                    isMulti
                                    isSearchable
                                    className={(errors.categories?.type)}
                                    defaultOptions
                                    placeholder=" - Выберите категорий - "
                                    loadOptions={loadCategories}
                                />
                            )}
                        />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Исполнители *:</p>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="singers"
                            render={({ field }) => (
                                <AsyncCreatableSelect
                                    {...field}
                                    isMulti
                                    className={(errors.singers?.type)}
                                    placeholder=" - Выберите исполнителей - "
                                    profa={PROFA_ARTIST}
                                />
                            )}
                        />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Название *:</p>
                        <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Версия:</p>
                        <input {...register("version", { required: false })} className={"formColOneRowFlexInput " + (errors.version?.type)} type="text" placeholder="Введите версию" />
                    </div>

                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Авторы текста:</p>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="author_texts"
                            render={({ field }) => (
                                <AsyncCreatableSelect
                                    {...field}
                                    isMulti
                                    className={(errors.author_texts?.type)}
                                    placeholder=" - Выберите авторов текста - "
                                    profa={PROFA_AVTOR_TEKSTA}
                                />
                            )}
                        />
                    </div>

                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Композиторы:</p>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="compositors"
                            render={({ field }) => (
                                <AsyncCreatableSelect
                                    {...field}
                                    isMulti
                                    className={(errors.compositors?.type)}
                                    placeholder=" - Выберите композиторов - "
                                    profa={PROFA_KOMPOZITOR}
                                />
                            )}
                        />
                    </div>

                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Продюсеры:</p>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="producers"
                            render={({ field }) => (
                                <AsyncCreatableSelect
                                    {...field}
                                    isMulti
                                    className={(errors.producers?.type)}
                                    placeholder=" - Выберите продюсеров - "
                                    profa={PROFA_PRODYUSER}
                                />
                            )}
                        />
                    </div>

                    <div className="formColRowFlexDiv d-none">
                        <p className="formColOneRowFlexLabel">Картинка:</p>
                        <input {...register("file", { required: !model && !album_id })} ref={imageRef} type="file" accept="image/*" className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                            if (e.target.files.length > 0) {
                                var file = e.target.files[0];
                                setValue("file", file);
                                trigger('file');
                                var reader = new FileReader();
                                var url = reader.readAsDataURL(file);

                                reader.onloadend = function (e) {
                                    setPreviewImage([reader.result])
                                }
                            } else {
                                setValue("file", null);
                                trigger('file');
                                setPreviewImage(null);
                            }
                        }} />
                    </div>
                    <div className="my-3">
                        <a role="button" className="text-white-50" onClick={() => setCollapse(!collapse)}>{collapse ? "- Скрыть доп. инфо" : "+ Показать доп. инфо"}</a>
                    </div>

                    <Collapse in={collapse}>
                        <div className="mb-3">
                            <div className="formColRowFlexDiv">
                                <p className="formColOneRowFlexLabel fs-14">Ссылка на файл:</p>
                                <input {...register("filename_mp3", { required: true })} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.filename_mp3?.type)*/} type="text" readOnly placeholder="Ссылка на mp3 файл" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel fs-14">Ссылка на файл ogg:</p>
                                <input {...register("filename_ogg", { required: true })} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.filename_ogg?.type)*/} type="text" readOnly placeholder="Ссылка на ogg файл" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel fs-14">Ссылка на заставку:</p>
                                <input {...register("background", { required: false })} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.background?.type)*/} type="text" readOnly placeholder="Ссылка на файл заставки" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel fs-14">Название файла 64K:</p>
                                <input {...register("filename_mp3_64", { required: true })} className={"formColOneRowFlexInput fs-14 cursor-pointer "/* + (errors.filename_mp3_64?.type)*/} type="text" readOnly placeholder="Название файла на сервере 64K" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel fs-14">Название файла 64K ogg:</p>
                                <input {...register("filename_ogg_64", { required: true })} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.filename_ogg_64?.type)*/} type="text" readOnly placeholder="Название файла на сервере 64K ogg" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv">
                                <p className="formColOneRowFlexLabel fs-14">Продолжительность:</p>
                                <input {...register("duration", { required: true })} className={"formColOneRowFlexInput fs-14 " /*+ (errors.duration?.type)*/} type="text" readOnly placeholder="Продолжительность файла" />
                            </div>
                        </div>
                    </Collapse>

                    <div className="d-flex justify-content-end">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <i className="fa fa-spinner fa-2x rotating "></i>
                            ) : ("Сохранить")}
                        </button>
                    </div>
                </form>
            </div>
            <div className="col-lg-4">
                <div className="text-end">
                    {props.closeModal && <a role="button" onClick={props.closeModal}><i className="fa fa-close fa-2x text-white"></i></a>}
                </div>
                <form className={`mb-4 ${isServerFileLoading || isServerBackgroundFileLoading ? 'd-block' : 'd-none'}`}>
                    <div className="formColRowFlexDiv d-none">
                        <p className="formColOneRowFlexLabel">Загрузка файла на сервер *:</p>
                        <input ref={serverFileInput} onChange={(e) => {
                            if (e.target.files.length > 0) {
                                setServerFile(e.target.files[0]);
                                setProgress(0);
                            }
                        }} className={"formColOneRowFlexInput " + (errors.server_file?.type)} type="file" accept="audio/*" />
                    </div>

                    <div className="formColRowFlexDiv d-none">
                        <p className="formColOneRowFlexLabel">Загрузка заставки на сервер:</p>
                        <input ref={serverBackgroundFileInput} onChange={(e) => {
                            if (e.target.files.length > 0) {
                                setServerBackgroundFile(e.target.files[0]);
                                setBackgroundProgress(0);
                            }
                        }} className={"formColOneRowFlexInput " + (errors.server_background_file?.type)} type="file" accept="image/gif" />
                    </div>

                    {isServerFileLoading && <div>
                        <p className="text-white">Загрузка файла</p>
                        <div className="row">
                            <div className="col-11">
                                <div className="progress mb-4">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                            <div className="col-1">
                                {progress == 100 && <i className="fa fa-check text-success"></i>}
                            </div>
                        </div>

                    </div>}
                    {isServerFileLoading && progress == 100 && <div>
                        <p className="text-white">Конвертация файла</p>
                        <div className="row">
                            <div className="col-11">
                                <div className="progress mb-4">
                                    <div className={`progress-bar ${isDone || isError ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{ width: `${100}%` }}></div>
                                </div>
                            </div>
                            <div className="col-1">
                                {isDone && <i className="fa fa-check text-success"></i>}
                                {isError && <i className="fa fa-close text-danger"></i>}
                            </div>
                        </div>

                    </div>}

                    {isServerBackgroundFileLoading && <div>
                        <p className="text-white">Загрузка заставки</p>
                        <div className="row">
                            <div className="col-11">
                                <div className="progress mb-4">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${backgroundProgress}%` }}></div>
                                </div>
                            </div>
                            <div className="col-1">
                                {backgroundProgress == 100 && <i className="fa fa-check text-success"></i>}
                            </div>
                        </div>

                    </div>}

                    <div className="d-flex justify-content-end d-none">
                        <button type="button" onClick={() => {
                            uploadToServer();
                        }}>{isServerFileLoading ? <i className="fa fa-spinner fa-2x rotating "></i> : "Загрузить"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}