import axios from 'axios';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../components/context';
import Sidebar from '../../../components/Settings/Sidebar';
import { useIsMounted } from '../../../utils/functions';
import { get } from '../../../utils/request';

import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip-lite';

const controllerName = "person";
const title = "Персоны";

const CancelToken = axios.CancelToken;
let cancel;

export default function Persons() {
    const authContext = React.useContext(AuthContext);

    const [models, setModels] = useState([]);
    const [profa, setProfa] = useState("");
    const [pagination, setPagination] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("person.id");
    const [limit, setLimit] = useState(20);
    const [sortType, setSortType] = useState("desc");
    const [page, setPage] = useState(1);

    const isMounted = useIsMounted();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`${controllerName}/page?limit=${limit}${search ? `&search=${search}` : ``}${sort ? `&sort=${sort}` : ``}${page ? `&page=${page}` : ``}${sortType ? `&sort_type=${sortType}` : ``}${profa ? `&profa=${profa}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (isMounted) {
            if (res) {
                setModels(res.models);
                setPagination(res.pagination)
            }

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        fetchModels();
    }, [search, sort, page, limit, sortType, profa]);

    const handleDelete = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                if (isMounted) {
                    setModels(models.filter((model) => model.id !== id));
                    toast.success(res.message);
                }

                authContext.loadPersons();
            }
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        Настройки
                                    </span>
                                </h3>
                                <NavLink to={`/settings/${controllerName}/create`} className="show-all btn btn-success fs-12">
                                    <span>
                                        Добавить
                                    </span>
                                    <i className="fa fa-plus"></i>
                                </NavLink>
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Поиск..." value={search} onChange={(e) => { setSearch(e.target.value); setPage(1); }} />
                            </div>

                            <div className="d-flex align-items-center mb-3 col-12 col-md-3">
                                <select className="form-select form-select-sm w-auto" value={profa} onChange={(e) => setProfa(e.target.value)}>
                                    <option value=""> - Профа - </option>
                                    {authContext.profaState.map((profa, index) => {
                                        return <option value={profa.value} key={index}>{profa.label}</option>
                                    })}
                                </select>

                                <select className="form-select form-select-sm w-auto ms-2" value={limit} onChange={(e) => setLimit(Number.parseInt(e.target.value))}>
                                    <option value="20"> 20 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                    <option value="500"> 500 </option>
                                </select>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-md-3">
                                <Sidebar />
                            </div>
                            <div className="col-md-9">
                                <table className="table table-hover text-white table-borderless table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "40px" }}></th>
                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("name");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Название {sort == "name" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>

                                            <th className="text-start vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("profa");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Профа {sort == "profa" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th>Связанные <br/> музыки / видео</th>
                                            <th className="text-center vertical-align-middle"><a role="button" onClick={() => {
                                                setSort("created_at");
                                                setSortType(sortType == 'asc' ? 'desc' : 'asc');
                                            }}>Дата добавления {sort == "created_at" && <i className={`fa fa-sort-amount-${sortType}`}></i>}</a></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!isLoading && models.length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                                        {isLoading && <tr className="text-white text-center"><td colSpan="10">
                                            <i className="fa fa-spinner fa-2x rotating text-white"></i>
                                        </td></tr>}

                                        {models.map((model, index) => {
                                            return (
                                                <tr key={index} className="vertical-align-middle text-white">
                                                    <td className="text-end">
                                                        <a href={model.image} target="_blank" className="text-white">
                                                            <img className="border-radius-2" src={model.image} alt="" style={{ width: "40px" }} />
                                                        </a>
                                                    </td>
                                                    <td className="text-start"><NavLink to={`/settings/${controllerName}/` + model.id} className="text-white">{model.name}</NavLink></td>
                                                    <td className="text-start">{authContext.profaState.filter((profa) => model.profas.indexOf(Number.parseInt(profa.value)) > -1).map((profa) => profa.label).join(", ")}</td>
                                                    <td>{model.musics} / {model.videos}</td>
                                                    <td>
                                                        {model.created_at && model.created_at.split(" ")[0].split("-").reverse().join(".")}
                                                    </td>
                                                    <td>

                                                        <div className="d-flex">
                                                            <NavLink to={`/settings/${controllerName}/` + model.id} className="text-white me-3">
                                                                <i className="fa fs-18 fa-pencil"></i>
                                                            </NavLink>
                                                            <a role="button" className="text-white me-3" onClick={() => {
                                                                handleDelete(model.id);
                                                            }}>
                                                                <i className="fa fs-18 fa-trash"></i>
                                                            </a>
                                                            <Tooltip content={"Перейти на сайт"} className='target d-inline'>
                                                                <a className="text-white" href={`https://rizanova.uz/person/${model.id}`} target="_blank">
                                                                    <i className="fa fs-18 fa-link"></i>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip content={(
                                                                <div>
                                                                    <p className='mb-0'>{model.name}</p>
                                                                    <p className='mb-0'>{"Добавил: " + model.admins?.created_by + " в " + model.admins?.created_at}</p>
                                                                    {model.admins?.updated_at != model.admins?.created_at && model.admins?.updated_at && <p className='mb-0'>{"Обновлен: " + model.admins.updated_by + " в " + model.admins?.updated_at}</p>}
                                                                </div>
                                                            )} className='target d-inline'>
                                                                <a role="button" className='ms-4 text-white'>
                                                                    <i className="fa fa-info-circle"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>

                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {!isLoading && pagination && Math.ceil(pagination.totalCount / limit) > 1 && <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            pageCount={pagination ? Math.ceil(pagination.totalCount / limit) : null}
                            marginPagesDisplayed={7}
                            pageRangeDisplayed={5}
                            onPageChange={(p) => {
                                setPage(p.selected + 1);
                            }}
                            forcePage={parseInt(page - 1)}
                            containerClassName={'pagination pagination-sm justify-content-center mt-5'}
                            activeClassName={'active'}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />}
                    </div>
                </div>
            </div>
        </section >
    );
}