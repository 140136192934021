import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../components/context';
import { post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "admin";
const title = "Изменить пароль";

export default function PasswordHandle() {
    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useIsMounted();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    React.useEffect(() => {
        document.title = title;
    }, []);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/change-password`, arrayToFormData({
            ...data
        }));

        if (res?.status === "success") {
            if (isMounted) {
                reset();

                toast.success(res.message);
            }
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to="/settings" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Новый пароль *:</p>
                                        <input autoComplete="off" {...register("password", { required: true })} className={"formColOneRowFlexInput " + (errors.password?.type)} type="text" placeholder="Введите новый пароль" />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}