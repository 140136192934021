import React from "react";
import { useState } from "react";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import { arrayToFormData, useIsMounted } from "../utils/functions";
import { get, post } from "../utils/request";

const customStyles = {
    content: {
        top: '12%',
        left: '25%',
        right: '25%',
        bottom: '12%',
        backgroundColor: "#232636"
    },
};

export default function SelectCategoryModal({ isOpen, setIsOpen, musicIds, setMusicIds, fetchData }) {
    function closeModal() {
        setIsOpen(false);
    }

    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [ids, setIds] = useState([]);
    const [search, setSearch] = useState("");

    const [categories, setCategories] = useState([]);

    const isMounted = useIsMounted();

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            let res = await post(`category/check-music`, arrayToFormData({ ids: musicIds }));

            if (isMounted) {
                console.log(res.sort((a, b) => {
                    if (a.music_count < b.music_count) {
                        return 1;
                    }
                    if (a.music_count > b.music_count) {
                        return -1;
                    }
                    return 0;
                }));

                setModels(res.sort((a, b) => {
                    if (a.music_count < b.music_count) {
                        return 1;
                    }
                    if (a.music_count > b.music_count) {
                        return -1;
                    }
                    return 0;
                }));
                setCategories(res.map(category => {
                    return ({ category_id: category.id, state: Number.parseInt(category.music_count) == musicIds.length ? 1 : (Number.parseInt(category.music_count) > 0 ? 2 : 0) });
                }));
                setIds([]);

                setIsLoading(false);
            }
        }

        if (isOpen)
            fetchData();
    }, [isOpen]);

    const save = async () => {
        if (changed) {
            let res = await post(`category/set-categories`, arrayToFormData({
                musics: musicIds,
                categories
            }));

            if (res.status == "success") {
                toast.success("Успешно добавлены");

                setMusicIds([]);

                // if (fetchData) fetchData();
            } else {
                toast.error(res.message);
            }

            closeModal();
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <div className="d-flex justify-content-between mb-3 text-white-50">
                <span>Выбранных музыки:  <b>{musicIds.length}</b></span>
                <a role="button" onClick={closeModal}><i className="fa fa-close fa-2x text-white"></i></a>
            </div>
            <div className="d-flex">
                <div className="w-100">
                    <div className="w-100">
                        <input type="text" className="form-control" placeholder="Поиск" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>
            </div>

            <table className="table text-white table-borderless table-hover text-center mt-4">
                <thead>
                    <tr>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ width: "40px" }}></th>
                        <th className="text-start">Название UZ</th>
                        <th className="text-start">Название RU</th>
                    </tr>
                </thead>

                <tbody>
                    {!isLoading && models.filter(model => search === "" || model.name_uz.toLowerCase().indexOf(search.toLowerCase()) > -1 || model.name_ru.toLowerCase().indexOf(search.toLowerCase()) > -1).length === 0 && <tr className="text-white text-center"><td colSpan="10">Ничего не найдено</td></tr>}

                    {isLoading && <tr className="text-white text-center"><td colSpan="10">
                        <i className="fa fa-spinner fa-2x rotating text-white"></i>
                    </td></tr>}

                    {models.filter(model => search === "" || model.name_uz.toLowerCase().indexOf(search.toLowerCase()) > -1 || model.name_ru.toLowerCase().indexOf(search.toLowerCase()) > -1).map((model, index) => {
                        let category = categories.find(m => m.category_id == model.id);
                        let state = category ? category.state : 0;

                        return (
                            <tr key={index} className="vertical-align-middle text-white">
                                <td>
                                    <div onClick={() => {
                                        let new_categories = categories.map(cat => {
                                            if (cat.category_id == model.id) {
                                                return {
                                                    ...cat,
                                                    state: state == 2 || state == 1 ? 0 : 1
                                                };
                                            } else {
                                                return {
                                                    ...cat
                                                };
                                            }
                                        });
                                        setCategories(new_categories);
                                        setChanged(true);
                                    }} className="checking" style={{ width: "18px", height: "18px", backgroundColor: state == 1 || state == 2 ? "#0d6efd" : "white", borderRadius: "4px", display: "flex", alignItems: 'center', justifyContent: "center", cursor: "pointer" }}>
                                        {state == 1 ? <i className="fa fa-check text-white"></i> : (state == 2 ? <i className="fa fa-minus text-white"></i> : <></>)}
                                    </div>
                                </td>
                                <td className="text-end">
                                    <img className="border-radius-2" src={model.image} alt="" style={{ width: "40px" }} />
                                </td>
                                <td className="text-start">{model.name_uz}</td>
                                <td className="text-start">{model.name_ru}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="text-end">
                <button className={`btn ${!changed ? 'btn-danger' : 'btn-success'}`} onClick={() => {
                    if (changed) {
                        save();
                    } else {
                        closeModal();
                    }

                }}>
                    {isLoading ? <i className="fa fa-spinner rotating"></i> : (!changed ? 'Закрыть' : 'Добавить')}
                </button>
            </div>
        </Modal>
    );
}