import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import MusicForm from '../../components/forms/MusicForm';

const controllerName = "music";
const title = "Музыка";

export default function MusicHandle() {
    const { album_id, singer_id } = useParams();

    const { id } = useParams();

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <nav className="title-nav mb-2 flex-wrap">
                                    <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                        <h3 className="title-1 mt-0 mb-0 d-flex">
                                            <span className="me-3">
                                                {title}
                                            </span>
                                        </h3>
                                        <NavLink to={`/${controllerName}${album_id ? `/album/${album_id}` : ``}`} className="show-all btn btn-secondary fs-12 me-3">
                                            <span>
                                                Назад
                                            </span>
                                            <i className="fa fa-angle-left"></i>
                                        </NavLink>
                                        <NavLink to={`/album/create`} className="show-all btn btn-secondary fs-12">
                                            <span>
                                                Добавить альбом
                                            </span>
                                            <i className="fa fa-plus"></i>
                                        </NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <MusicForm album_id={album_id} singer_id={singer_id} id={id} />
                    </div>
                </div>
            </div>
        </section>
    );
}