import React from "react";
import axios from "axios";
import { useState } from "react";
import { arrayToFormData } from "../utils/functions";
import Modal from 'react-modal';
import MusicForm from "./forms/MusicForm";
import { get } from "../utils/request";
import { toast } from "react-toastify";

const customStyles = {
    content: {
        top: '135px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        backgroundColor: "#232636"
    },
};

const controllerName = "music";

export default function ExistingMusic(props) {
    const [isOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModalWithFetch() {
        setIsOpen(false);

        if (props.fetchData)
            props.fetchData();
    }

    function closeModal() {
        setIsOpen(false);

        if (props.fetchData)
            props.fetchData();
    }

    const handleDelete = async (id) => {
        if (props.music.status == 3){
            if (window.confirm("Удалить музыку?")) {
                let res = await get(`${controllerName}/delete?id=` + id);
    
                if (res?.status === "success") {
                    toast.success("Музыка удалена.");
    
                    if (props.fetchData) {
                        props.fetchData();
                    }
                }
            }
        } else {
            if (window.confirm("Удалить из альбома?")) {
                let res = await get(`${controllerName}/delete-from-album?id=` + id + "&album_id=" + props.album_id);
    
                if (res?.status === "success") {
                    toast.success("Музыка удалена из альбома.");
    
                    if (props.fetchData) {
                        props.fetchData();
                    }
                }
            }
        }
        
    }

    return (
        <li className="list-group-item list-group-item-dark py-2">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <MusicForm id={props.music.id} closeModalWithFetch={closeModalWithFetch} closeModal={closeModal}/>
            </Modal>
            <div className="d-flex align-items-center">
                {props.dragHandle}
                <a role="button" className="w-100 text-white d-block">
                    {props.music.name}
                </a>
                {<>
                    <a role="button" className="text-white me-3" onClick={() => openModal()}>
                        <i className="fa fa-pencil"></i>
                    </a>
                    <a role="button" className="text-white" onClick={() => {
                        handleDelete(props.music.id);
                    }}>
                        <i className="fa fa-trash"></i>
                    </a>
                </>}
            </div>
        </li>
    );
}