import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../components/context';
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "subscription";
const title = "Подарить подписку";

export default function CreateActiveSubscription() {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [tariffs, setTariffs] = useState([]);

    const isMounted = useIsMounted();

    let history = useHistory();

    const defaultValues = {
        user_id: "",
        tariff_id: "",
    }

    const { register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {
        document.title = title;

        const fetchTariffs = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/index`);

            if (isMounted) {
                setTariffs(res.models);

                setIsLoading(false);
            }
        }

        fetchTariffs();
    }, []);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/gift`, arrayToFormData({
            ...data
        }));

        if (res?.status === "success") {
            if (isMounted) {
                toast.success(res.message);
                reset(defaultValues);
            }
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to="/active-subscriptions" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">ID пользователя *:</p>
                                        <input {...register("user_id", { required: true })} className={"formColOneRowFlexInput " + (errors.user_id?.type)} type="text" placeholder="U0000" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Тариф *:</p>
                                        <select {...register("tariff_id", { required: true })} className={"formColOneRowFlexInput " + (errors.tariff_id?.type)}>
                                            {tariffs.filter(tariff => tariff.status == 1).map(tariff => {
                                                return <option value={tariff.id}>{tariff.name} - {tariff.price} ({tariff.month} месяц)</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}