import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from '../../../components/context';
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const controllerName = "person";
const title = "Персоны";

export default function PersonHandle() {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);

    const [cropData, setCropData] = useState(null);
    const [cropper, setCropper] = useState();

    const isMounted = useIsMounted();

    const { id } = useParams();

    let [previewImage, setPreviewImage] = useState(null);

    let history = useHistory();

    const imageRef = useRef();

    const defaultValues = {
        profas: [],
        name: "",
        full_name: "",
        birthday: "",
        death_date: "",
        description_uz: "",
        description_ru: "",

        nickname: "",
        social_youtube: "",
        social_instagram: "",
        social_tiktok: "",
        // social_telegram: "",
        // social_facebook: "",
        // store_apple_music: "",
        // store_spotify: "",
        // store_deezer: "",
        // store_tidal: "",
        // store_boomplay: "",
        // store_amazon: "",
        // store_soundcloud: "",
        // store_yandex_music: "",
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }


    }, []);

    React.useEffect(() => {
        if (model) {
            reset({
                ...model,
                // profa: authContext.profaState.find((profa) => profa.value == model.profa),
                profas: authContext.profaState.filter((profa) => model.profas.indexOf(Number.parseInt(profa.value)) > -1),
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            profas: data.profas.map((model) => model.value),
            file: data.file ? data.file : null,
            profa: data.profa ? data.profa : "",
            created_by: data.created_by ? data.created_by : "",
            updated_by: data.updated_by ? data.updated_by : "",

            nickname: data.nickname ?? "",
            social_youtube: data.social_youtube ?? "",
            social_instagram: data.social_instagram ?? "",
            social_tiktok: data.social_tiktok ?? "",
            // social_telegram: data.social_telegram ?? "",
            // social_facebook: data.social_facebook ?? "",
            // store_apple_music: data.store_apple_music ?? "",
            // store_spotify: data.store_spotify ?? "",
            // store_deezer: data.store_deezer ?? "",
            // store_tidal: data.store_tidal ?? "",
            // store_boomplay: data.store_boomplay ?? "",
            // store_amazon: data.store_amazon ?? "",
            // store_soundcloud: data.store_soundcloud ?? "",
            // store_yandex_music: data.store_yandex_music ?? "",
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    history.push(`/settings/${controllerName}/` + res.model.id)
                else setModel(res.model);

                toast.success(res.message);
            }

            authContext.loadPersons();
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadProfas = (inputValue, callback) => {
        if (callback)
            callback(authContext.profaState.filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title} {!!model && " — " + model.name}
                                    </span>
                                </h3>
                                <NavLink to="/settings" className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">

                            <div className="col-lg-12">
                                <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div style={{ overflow: previewImage ? "unset" : 'hidden' }} className={"uploaded-image-square border-radius-5 " + (errors.file?.type == "required" ? "required" : "")}>

                                            {previewImage ?
                                                (cropData ? <Cropper
                                                    style={{ height: "192px", width: "192px" }}
                                                    zoomTo={1}
                                                    initialAspectRatio={1}
                                                    aspectRatio={1}
                                                    src={cropData}
                                                    viewMode={2}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={false}
                                                    autoCropArea={1}
                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                    onInitialized={(instance) => {
                                                        setCropper(instance);
                                                    }}
                                                    guides={true}
                                                /> : <img className="w-100 d-block" src={previewImage} />)
                                                :
                                                (model && model.image ?
                                                    <a className="w-100" href={model.image} target="_blank">
                                                        <img className="w-100 d-block" src={model.image} />
                                                    </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        {previewImage && !cropData && <div><a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            setCropData(previewImage);
                                        }}>Редактировать</a></div>}

                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            imageRef.current.click();
                                        }}>{previewImage ? "Загрузить новую" : "Загрузить"}</a>

                                        {cropData && <div><a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            if (typeof cropper !== "undefined") {
                                                let data = cropper.getCroppedCanvas().toDataURL();

                                                setPreviewImage(data);
                                                setCropData(null);

                                                fetch(data)
                                                    .then(res => res.blob())
                                                    .then(blob => {
                                                        const file = new File([blob], ("picture" + Math.random().toString(16).slice(2) + '.png'), { type: "image/png" });
                                                        setValue("file", file);
                                                        trigger("file");
                                                    })
                                            }
                                        }}>Применить</a></div>}

                                    </div>
                                </div>
                                <form className="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row align-items-start">
                                        <div className="col-md-6 admin-form me-5">
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Профа *:</p>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: true }}
                                                    name="profas"
                                                    render={({ field }) => (
                                                        <AsyncSelect
                                                            {...field}
                                                            isMulti
                                                            className={(errors.profa?.type)}
                                                            defaultOptions
                                                            placeholder=" - Выберите профу - "
                                                            loadOptions={loadProfas}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Псевдоним *:</p>
                                                <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите псевдоним персоны" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Ф.И.О.:</p>
                                                <input {...register("full_name", { required: false })} className={"formColOneRowFlexInput " + (errors.full_name?.type)} type="text" placeholder="Введите Ф.И.О." />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">День рождения:</p>
                                                <input {...register("birthday", { required: false })} className={"formColOneRowFlexInput " + (errors.birthday?.type)} type="date" placeholder="Введите день рождения" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Дата смерти:</p>
                                                <input {...register("death_date", { required: false })} className={"formColOneRowFlexInput " + (errors.death_date?.type)} type="date" placeholder="Введите дату смерти" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Биография UZ:</p>
                                                <textarea {...register("description_uz", { required: false })} className={"formColOneRowFlexInput " + (errors.description_uz?.type)} placeholder="Введите биографию на узбекском" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Биография RU:</p>
                                                <textarea {...register("description_ru", { required: false })} className={"formColOneRowFlexInput " + (errors.description_ru?.type)} placeholder="Введите биографию на русском" />
                                            </div>

                                            <div className="formColRowFlexDiv d-none">
                                                <p className="formColOneRowFlexLabel">Фото:</p>
                                                <input {...register("file", { required: !model })} accept="image/*" type="file" ref={imageRef} onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var file = e.target.files[0];
                                                        setValue("file", file);
                                                        trigger("file");
                                                        var reader = new FileReader();
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setPreviewImage(reader.result)
                                                        }
                                                    } else {
                                                        setValue("file", null);
                                                        trigger('file');
                                                        setPreviewImage(null);
                                                    }
                                                }} className={"formColOneRowFlexInput " + (errors.file?.type)} placeholder="Введите название" />
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <button type="submit" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <i className="fa fa-spinner fa-2x rotating "></i>
                                                    ) : ("Сохранить")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-5 admin-form">
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Никнейм:</p>
                                                <input {...register("nickname", { required: false })} className={"formColOneRowFlexInput " + (errors.nickname?.type)} type="text" placeholder="Введите никнейм персоны" />
                                            </div>
                                            <h6 className='text-white text-center mt-4'>Социальные сети</h6>
                                            <hr className='text-white bg-white mb-3' />
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">YouTube:</p>
                                                <input {...register("social_youtube", { required: false })} className={"formColOneRowFlexInput " + (errors.social_youtube?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Instagram:</p>
                                                <input {...register("social_instagram", { required: false })} className={"formColOneRowFlexInput " + (errors.social_instagram?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">TikTok:</p>
                                                <input {...register("social_tiktok", { required: false })} className={"formColOneRowFlexInput " + (errors.social_tiktok?.type)} type="text" />
                                            </div>
                                            {/* <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Telegram:</p>
                                                <input {...register("social_telegram", { required: false })} className={"formColOneRowFlexInput " + (errors.social_telegram?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Facebook:</p>
                                                <input {...register("social_facebook", { required: false })} className={"formColOneRowFlexInput " + (errors.social_facebook?.type)} type="text" />
                                            </div> */}
                                            {/* <h6 className='text-white text-center mt-4'>Магазины</h6>
                                            <hr className='text-white bg-white mb-3' />
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Apple Music:</p>
                                                <input {...register("store_apple_music", { required: false })} className={"formColOneRowFlexInput " + (errors.store_apple_music?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Spotify:</p>
                                                <input {...register("store_spotify", { required: false })} className={"formColOneRowFlexInput " + (errors.store_spotify?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Deezer:</p>
                                                <input {...register("store_deezer", { required: false })} className={"formColOneRowFlexInput " + (errors.store_deezer?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Yandex Music:</p>
                                                <input {...register("store_yandex_music", { required: false })} className={"formColOneRowFlexInput " + (errors.store_yandex_music?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">TIDAL:</p>
                                                <input {...register("store_tidal", { required: false })} className={"formColOneRowFlexInput " + (errors.store_tidal?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Boomplay:</p>
                                                <input {...register("store_boomplay", { required: false })} className={"formColOneRowFlexInput " + (errors.store_boomplay?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Amazon:</p>
                                                <input {...register("store_amazon", { required: false })} className={"formColOneRowFlexInput " + (errors.store_amazon?.type)} type="text" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">SoundCloud:</p>
                                                <input {...register("store_soundcloud", { required: false })} className={"formColOneRowFlexInput " + (errors.store_soundcloud?.type)} type="text" />
                                            </div> */}
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}