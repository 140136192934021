import { forwardRef, useContext, useEffect, useState } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import { PROFA_ARTIST } from '../config';
import { arrayToFormData, useIsMounted } from '../utils/functions';
import { post } from '../utils/request';
import { AuthContext } from './context';

export default forwardRef((props, ref) => {

    const authContext = useContext(AuthContext);

    const [options, setOptions] = useState(authContext.personState.sort((a, b) => a.label.localeCompare(b.label)).filter(person => person.model.profas.indexOf(props.profa) > -1));
    const [value, setValue] = useState(props.isMulti ? [] : undefined);

    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useIsMounted();

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleCreate = async (inputValue) => {
        setIsLoading(true);

        let res = await post(`person/create?creatable=1`, arrayToFormData({
            name: inputValue,
            profas: [props.profa],
        }));

        if (res?.status === "success") {
            if (isMounted) {
                await authContext.loadPersons();
                toast.success(res.message);
                let newPerson = { value: res.model.id, label: res.model.name };
                setValue(props.isMulti ? [...value, newPerson] : newPerson);
                props.onChange(props.isMulti ? [...value, newPerson] : newPerson);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadPersons = (profa = PROFA_ARTIST) => (inputValue, callback) => {
        // authContext.loadPersons().then(() => {
        if (callback)
            callback(authContext.personState.sort((a, b) => a.label.localeCompare(b.label)).filter(person => person.model.profas.indexOf(profa) > -1).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
        // });
    }

    const onChange = (newValue, actionMeta) => {
        setValue(props.isMulti ? [...newValue] : { ...newValue });
        props.onChange(newValue);
    };

    return <AsyncCreatable
        {...props}
        ref={ref}
        isLoading={isLoading}
        onCreateOption={handleCreate}
        formatCreateLabel={(inputValue) => `Создать персона "${inputValue}"`}
        defaultOptions
        onChange={onChange}
        options={options}
        value={value}
        loadOptions={loadPersons(props.profa)}
    />;
});