import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';

const controllerName = "partner";
const title = "Партнеры";

export default function PartnerHandle() {

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);


    const isMounted = useIsMounted();

    const { id } = useParams();

    const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm();

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }


    }, []);

    React.useEffect(() => {
        if (model) {
            reset({
                ...model,
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    reset();
                else setModel(res.model);

                toast.success(res.message);
            }
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title} {!!model && " — " + model.full_name}
                                    </span>

                                </h3>
                                <NavLink to={`/settings/${controllerName}`} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Ф.И.О. *:</p>
                                        <input {...register("full_name", { required: true })} className={"formColOneRowFlexInput " + (errors.full_name?.type)} type="text" placeholder="Введите Ф.И.О." />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Логин *:</p>
                                        <input readOnly {...register("username", { required: true })} className={"formColOneRowFlexInput " + (errors.username?.type)} type="text" placeholder="Введите логин" />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Пароль *:</p>
                                        <input {...register("password", { required: !model })} className={"formColOneRowFlexInput " + (errors.password?.type)} type="text" placeholder={model ? "Введите новый пароль (если хотите изменить)" : "Введите пароль"} />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Фото:</p>
                                        <input {...register("file")} type="file" className={"formColOneRowFlexInput " + (errors.file?.type)} placeholder="Введите название" />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}