import { useState, useEffect } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import faker from 'faker';

import { get } from '../../utils/request';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },

    },
    animations: {
        tension: {
            duration: 2000,
            easing: 'linear',
            from: 0.3,
            to: 0,
            loop: true
        }
    },
    // layout: {
    //     padding: 20
    // }
    
};

export default function RegisterUsers() {
    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {

            let res = await get(`chart/chart-registers`);

            setData({
                labels: res.models.map(m => m.label.split("-").reverse().join(".")),
                datasets: [
                    {
                        label: '',
                        data: res.models.map(m => m.value),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    }
                ]
            });

            setCount(res.count);

            setIsLoading(false);
        }

        fetchData();
    }, []);

    if (!data) return <h6 className="text-white">
        Зарегистрованные пользователи
    </h6>;

    return (
        <>
            <h6 className="text-white mb-4">
                Зарегистрованные пользователи ({count})
            </h6>

            <Line options={options} data={data} />
        </>
    );
}