import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/context';
import { useForm, Controller } from "react-hook-form";
import { arrayToFormData, toDataURL, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request';
import axios from 'axios';
import Select from 'react-select';
import { FILE_HOST_URL, PROFA_AKTYOR, PROFA_ARTIST, PROFA_AVTOR_TEKSTA, PROFA_KOMPOZITOR, PROFA_LEYBL, PROFA_OPERATOR, PROFA_PRODYUSER, PROFA_REJISYOR, PROFA_SCENARIY, PROFA_XUDOJNIK, SERVICE_HOST_URL } from '../../config';
import AsyncCreatableSelect from '../../components/AsyncCreatableSelect';
import { Collapse } from 'react-bootstrap';
import ReactHlsPlayer from 'react-hls-player';
import AsyncSelect from 'react-select/async';
import moment from 'moment';

const controllerName = "clip";
const title = "Клипы";

let statuses = [
    { value: "1", label: 'Доступен всем' },
    { value: "0", label: 'Ограничить доступ' },
    { value: "2", label: 'Доступ по ссылке' },
    { value: "7", label: 'Премьера' },
    { value: "12", label: 'Модерация' },
    { value: "15", label: 'Отклонен' },
];

export default function ClipHandle() {
    const authContext = React.useContext(AuthContext);

    const { singer_id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [collapse, setCollapse] = useState(false);

    let [previewImage, setPreviewImage] = useState(null);
    let [previewBannerImage, setPreviewBannerImage] = useState(null);
    let [previewPageImage, setPreviewPageImage] = useState(null);
    let [previewVideo, setPreviewVideo] = useState(null);

    const [screenshots, setScreenshots] = useState([]);

    const [selectedScreenshot, setSelectedScreenshot] = useState(null);

    const isMounted = useIsMounted();

    let history = useHistory();

    const imageRef = useRef();
    const bannerImageRef = useRef();
    const pageImageRef = useRef();

    const { id } = useParams();

    const defaultValues = {
        name: "",
        version: "",
        filename: "",
        filenames: "",
        subtitle_uz: "",
        subtitle_ru: "",
        release_date: moment().format("YYYY-MM-DD"),
        release_time: "11:00",
        status: statuses[0],
        duration: "",
        is_premiere: false,
        singers: singer_id ? [authContext.personState.find((person) => person.value === singer_id)] : [],
        producers: [],
        leybls: [],
        author_texts: [],
        compositors: [],
        operators: [],
        xudojniks: [],
        actors: [],
        directors: [],
        scenarists: [],
        send_push_notification: 0,
        is_push_notification_sent: 0
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, trigger, watch } = useForm({
        defaultValues
    });

    let authors = watch('singers');

    let watchIsHome = watch("is_home");
    let watchIsPage = watch("is_page");

    React.useEffect(() => {
        document.title = title;

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await get(`${controllerName}/get?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }
    }, []);

    React.useEffect(() => {
        if (model) {
            let dates = model.release_date ? model.release_date.split(" ") : [];

            let release_date = dates.length > 0 ? dates[0] : "";
            let release_time = dates.length > 0 ? dates[1] : "";

            if (model.screenshots == 1 && model.status == 5) {
                setScreenshots([
                    model.filenames?.replace("playlist.m3u8", "frame-1.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-2.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-3.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-4.jpg"),
                ]);
            }

            reset({
                name: model.name,
                version: model.version,
                image: model.image,
                image_convert: model.image_convert,
                subtitle_uz: model.subtitle_uz,
                subtitle_ru: model.subtitle_ru,
                filename: model.filename,
                filenames: model.filenames,
                duration: model.duration,
                is_premiere: model.is_premiere,
                is_home: model.is_home,
                singers: [...authContext.personState.filter((person) => model.authors.indexOf(person.value) > -1)].sort((a, b) => model.authors.indexOf(a.value) - model.authors.indexOf(b.value)),
                compositors: [...authContext.personState.filter((person) => model.compositors.indexOf(person.value) > -1)].sort((a, b) => model.compositors.indexOf(a.value) - model.compositors.indexOf(b.value)),
                author_texts: [...authContext.personState.filter((person) => model.author_texts.indexOf(person.value) > -1)].sort((a, b) => model.author_texts.indexOf(a.value) - model.author_texts.indexOf(b.value)),
                leybls: [...authContext.personState.filter((person) => model.leybls.indexOf(person.value) > -1)].sort((a, b) => model.leybls.indexOf(a.value) - model.leybls.indexOf(b.value)),
                producers: [...authContext.personState.filter((person) => model.producers.indexOf(person.value) > -1)].sort((a, b) => model.producers.indexOf(a.value) - model.producers.indexOf(b.value)),
                operators: [...authContext.personState.filter((person) => model.operators.indexOf(person.value) > -1)].sort((a, b) => model.operators.indexOf(a.value) - model.operators.indexOf(b.value)),
                xudojniks: [...authContext.personState.filter((person) => model.xudojniks.indexOf(person.value) > -1)].sort((a, b) => model.xudojniks.indexOf(a.value) - model.xudojniks.indexOf(b.value)),
                actors: [...authContext.personState.filter((person) => model.actors.indexOf(person.value) > -1)].sort((a, b) => model.actors.indexOf(a.value) - model.actors.indexOf(b.value)),
                directors: [...authContext.personState.filter((person) => model.directors.indexOf(person.value) > -1)].sort((a, b) => model.directors.indexOf(a.value) - model.directors.indexOf(b.value)),
                scenarists: [...authContext.personState.filter((person) => model.scenarists.indexOf(person.value) > -1)].sort((a, b) => model.scenarists.indexOf(a.value) - model.scenarists.indexOf(b.value)),
                status: model.status == 5 ? statuses[0] : statuses.find((status) => status.value == model.status),
                music_id: model.music ? { value: model.music.id, label: model.music.name } : null,
                release_date: release_date,
                release_time: release_time,
                is_push_notification_sent: model.is_push_notification_sent,
                send_push_notification: model.send_push_notification,
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : '',
            banner_file: data.banner_file ? data.banner_file : '',
            page_file: data.page_file ? data.page_file : '',
            singers: data.singers.map((model) => model.value),
            compositors: data.compositors.map((model) => model.value),
            producers: data.producers.map((model) => model.value),
            operators: data.operators.map((model) => model.value),
            xudojniks: data.xudojniks.map((model) => model.value),
            author_texts: data.author_texts.map((model) => model.value),
            leybls: data.leybls.map((model) => model.value),
            actors: data.actors.map((model) => model.value),
            scenarists: data.scenarists.map((model) => model.value),
            directors: data.directors.map((model) => model.value),
            status: data.status ? data.status.value : 0,
            music_id: data.music_id ? data.music_id.value : "",
            is_premiere: data.is_premiere ? 1 : 0,
            is_home: data.is_home ? 1 : 0,
            is_page: data.is_page ? 1 : 0,
            release_date: data.release_time ? data.release_date + " " + data.release_time : data.release_date,
            image_convert: selectedScreenshot ? selectedScreenshot : data.image_convert,
            image: selectedScreenshot ? selectedScreenshot : data.image,
            send_push_notification: data.send_push_notification ? 1 : 0,
            is_push_notification_sent: data.is_push_notification_sent ? 1 : 0,
        }));

        if (res?.status === "success") {
            if (isMounted) {

                window.onbeforeunload = function (e) {
                    return null;
                };

                if (!id) {
                    history.push("/clip/" + res.model.id)
                    // reset(defaultValues);
                }
                else setModel(res.model);

                toast.success(res.message);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadMusics = async (inputValue, callback) => {
        let res = await get(`music/for-clip`, {
            params: {
                search: inputValue,
                author_id: authors.map(a => a.value)
            }
        });
        let data = res.map(m => ({ value: m.id, label: m.name + (m.version != null ? " (" + m.version + ")" : "") }));

        callback(data);
        return data;
    }

    const [serverFile, setServerFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isServerFileLoading, setIsServerFileLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    let serverFileInput = useRef();

    React.useEffect(() => {
        if (serverFile) {
            uploadToServer();
            window.onbeforeunload = function (e) {
                e = e || window.event;

                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Закрыть?';
                }

                // For Safari
                return 'Закрыть?';
            };
        }
    }, [serverFile]);

    const uploadToServer = async () => {
        if (serverFile) {
            setIsServerFileLoading(true);
            setIsDone(false);

            let name = serverFile.name.replace(".mp4", "");

            if (name.indexOf(" - ") > -1) {
                setValue("name", name.split(" - ")[1]);
            } else
                setValue("name", name);

            let res = await axios.post(`${SERVICE_HOST_URL}:3010/upload?dir=${controllerName}`, arrayToFormData({
                file: serverFile
            }), {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                setValue("filename", res.data.filename);
                setValue("filenames", res.data.filenames);
                setScreenshots(res.data.screenshots);
                setPreviewVideo(res.data.filenames);

                setValue("duration", res.data.duration);

                if (serverFileInput)
                    serverFileInput.current.value = null;
            }

            setIsDone(true);

        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-3">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>
                                </h3>
                                <NavLink to={`/${controllerName}`} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className={"uploaded-image border-radius-5 " + (errors.file?.type == "required" ? "required" : "")}>
                                            {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a href={model.image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            imageRef.current.click();
                                        }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                                    </div>

                                    <div className="text-center me-3 mb-4">
                                        <div className={"uploaded-audio border-radius-5 " + (previewVideo || model && model.filenames ? '' : ' bg ') + (errors.filename?.type == "required" ? "required" : "")}>
                                            {previewVideo ? <video className="w-100 d-block" controls>
                                                <source src={previewVideo} type="video/mp4" />
                                            </video> : (model && model.filenames ? <ReactHlsPlayer
                                                src={model.filenames}
                                                autoPlay={false}
                                                controls={true}
                                                width="100%"
                                                height="auto"
                                            /> : <p className="text-white w-100 my-0">Video</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            serverFileInput.current.click();
                                        }}>{previewVideo ? "Изменить" : "Загрузить"}</a>
                                    </div>

                                    <div className="w-100">
                                        <div className="formColRowFlexDiv">
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="status"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder="Параметы доступа"
                                                        className="basic-single w-100"
                                                        classNamePrefix="select"
                                                        defaultValue={{ value: "1", label: 'Доступен всем' }}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={statuses}
                                                    />
                                                )}
                                            />

                                        </div>
                                        <div className="formColRowFlexDiv">
                                            <input type="checkbox" className="btn-check" id="is_premiere" autoComplete="off" {...register("is_premiere")} />
                                            <label className="btn btn-outline-success w-100 premiere" htmlFor="is_premiere">Премьера</label>
                                        </div>
                                        {model && model.premiere_left && <div className="formColRowFlexDiv text-white">
                                            Осталось {model.premiere_left}
                                        </div>}
                                        <div className="formColRowFlexDiv">
                                            <input type="checkbox" className="btn-check" id="send_push_notification" autoComplete="off" {...register("send_push_notification")} />
                                            <label className="btn btn-outline-warning btn-sm w-100 premiere" htmlFor="send_push_notification">Отправить Push-уведомление</label>
                                        </div>
                                        {model && model.is_push_notification_sent && <div className="formColRowFlexDiv text-white" style={{ fontSize: 12 }}>
                                            Push-уведомление отправлен
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <form className=" mb-4">

                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Загрузка файла на сервер *:</p>
                                        <input ref={serverFileInput} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                setServerFile(e.target.files[0]);
                                                setProgress(0);
                                            }
                                        }} className={"formColOneRowFlexInput " + (errors.server_file?.type)} type="file" accept="video/*" />
                                    </div>
                                    {isServerFileLoading && <div>
                                        <p className="text-white">Загрузка файла</p>
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="progress mb-4">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                {progress == 100 && <i className="fa fa-check text-success"></i>}
                                            </div>
                                        </div>

                                    </div>}
                                    {isServerFileLoading && progress == 100 && <div>
                                        <p className="text-white">Конвертация файла</p>
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="progress mb-4">
                                                    <div className={`progress-bar ${isDone ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{ width: `${100}%` }}></div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                {isDone && <i className="fa fa-check text-success"></i>}
                                            </div>
                                        </div>

                                    </div>}
                                    <div className="d-flex justify-content-end mb-4 d-none">
                                        <button type="button" onClick={() => {
                                            uploadToServer();
                                        }}>{isServerFileLoading ? <i className="fa fa-spinner fa-2x rotating "></i> : "Загрузить"}</button>
                                    </div>


                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel fs-12">Название файла m3u8:</p>
                                        <input {...register("filenames", { required: false })} className={"formColOneRowFlexInput fs-12 cursor-pointer " + (errors.filenames?.type)} type="text" readOnly placeholder="Название файла на сервере 2160p" onClick={(e) => {
                                            if (e.target.value) {
                                                window.open(e.target.value, '_blank');
                                            }
                                        }} />
                                    </div>

                                </form>

                            </div>


                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="row">

                                    <div className="col-md-6">
                                        <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Дата релиза *:</p>
                                                <input {...register("release_date", { required: true })} style={{ width: "150px" }} className={"formColOneRowFlexInput " + (errors.release_date?.type)} type="date" />
                                                <input type="time" {...register("release_time", { required: true })} style={{ width: "100px" }} className={"formColOneRowFlexInput " + (errors.release_time?.type)} />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Исполнители *:</p>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: true }}
                                                    name="singers"
                                                    render={({ field }) => (
                                                        <AsyncCreatableSelect
                                                            {...field}
                                                            isMulti
                                                            className={(errors.singers?.type)}
                                                            placeholder=" - Выберите исполнителей - "
                                                            profa={PROFA_ARTIST}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Название *:</p>
                                                <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Версия:</p>
                                                <input {...register("version", { required: false })} className={"formColOneRowFlexInput " + (errors.version?.type)} type="text" placeholder="Введите версию" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Музыка:</p>
                                                <Controller
                                                    key={authors.map(a => a.value).join(",")}
                                                    control={control}
                                                    rules={{ required: false }}
                                                    name="music_id"
                                                    render={({ field }) => (
                                                        <AsyncSelect
                                                            {...field}
                                                            className={(errors.music_id?.type)}
                                                            defaultOptions
                                                            isClearable
                                                            placeholder={authors.length == 0 ? " - Сперва выберите исполнителей - " : " - Выберите музыку - "}
                                                            loadOptions={loadMusics}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Авторы текста:</p>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: false }}
                                                    name="author_texts"
                                                    render={({ field }) => (
                                                        <AsyncCreatableSelect
                                                            {...field}
                                                            isMulti
                                                            className={(errors.author_texts?.type)}
                                                            placeholder=" - Выберите авторов текста - "
                                                            profa={(PROFA_AVTOR_TEKSTA)}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Композиторы:</p>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: false }}
                                                    name="compositors"
                                                    render={({ field }) => (
                                                        <AsyncCreatableSelect
                                                            {...field}
                                                            isMulti
                                                            className={(errors.compositors?.type)}
                                                            placeholder=" - Выберите композиторов - "
                                                            profa={(PROFA_KOMPOZITOR)}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="formColRowFlexDiv d-none">
                                                <p className="formColOneRowFlexLabel">Картинка:</p>
                                                <input {...register("file", { required: !model && !selectedScreenshot })} type="file" accept="image/*" ref={imageRef} className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var file = e.target.files[0];
                                                        setValue("file", file);
                                                        trigger('file');
                                                        var reader = new FileReader();
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setPreviewImage([reader.result]);
                                                            setSelectedScreenshot(null);
                                                        }
                                                    } else {
                                                        setValue("file", null);
                                                        trigger('file');
                                                        setPreviewImage(null);
                                                        setSelectedScreenshot(null);
                                                    }
                                                }} />
                                            </div>

                                            <div className="formColRowFlexDiv d-none">
                                                <p className="formColOneRowFlexLabel">Баннер:</p>
                                                <input {...register("banner_file")} type="file" ref={bannerImageRef} accept="image/*" className={"formColOneRowFlexInput " + (errors.banner_file?.type)} onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var file = e.target.files[0];
                                                        setValue("banner_file", file);
                                                        var reader = new FileReader();
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setPreviewBannerImage([reader.result])
                                                        }
                                                    } else {
                                                        setValue("banner_file", null);
                                                        trigger('banner_file');
                                                        setPreviewBannerImage(null);
                                                    }
                                                }} />
                                            </div>
                                            <div className="formColRowFlexDiv d-none">
                                                <p className="formColOneRowFlexLabel">Баннер на своем странице:</p>
                                                <input {...register("page_file")} type="file" ref={pageImageRef} accept="image/*" className={"formColOneRowFlexInput " + (errors.page_file?.type)} onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var file = e.target.files[0];
                                                        setValue("page_file", file);
                                                        var reader = new FileReader();
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setPreviewPageImage([reader.result])
                                                        }
                                                    } else {
                                                        setValue("page_file", null);
                                                        trigger('page_file');
                                                        setPreviewPageImage(null);
                                                    }
                                                }} />
                                            </div>

                                            <div className="my-3">
                                                <a role="button" className="text-white-50" onClick={() => setCollapse(!collapse)}>{collapse ? "- Скрыть доп. инфо" : "+ Показать доп. инфо"}</a>
                                            </div>

                                            <Collapse in={collapse}>
                                                <div className="mb-3">
                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Сценаристы:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="scenarists"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.scenarists?.type)}
                                                                    placeholder=" - Выберите сценаристов - "
                                                                    profa={(PROFA_SCENARIY)}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Операторы:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="operators"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.operators?.type)}
                                                                    placeholder=" - Выберите операторов - "
                                                                    profa={(PROFA_OPERATOR)}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Художники:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="xudojniks"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.xudojniks?.type)}
                                                                    placeholder=" - Выберите художников - "
                                                                    profa={(PROFA_XUDOJNIK)}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Режиссёры:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="directors"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.directors?.type)}
                                                                    placeholder=" - Выберите режиссёров - "
                                                                    profa={(PROFA_REJISYOR)}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Продюсеры:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="producers"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.producers?.type)}
                                                                    placeholder=" - Выберите продюсеров - "
                                                                    profa={(PROFA_PRODYUSER)}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Актёры:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="actors"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.actors?.type)}
                                                                    placeholder=" - Выберите актёры - "
                                                                    profa={(PROFA_AKTYOR)}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel">Лэйбл:</p>
                                                        <Controller
                                                            control={control}
                                                            rules={{ required: false }}
                                                            name="leybls"
                                                            render={({ field }) => (
                                                                <AsyncCreatableSelect
                                                                    {...field}
                                                                    isMulti
                                                                    className={(errors.leybls?.type)}
                                                                    placeholder=" - Выберите лэйблов - "
                                                                    profa={(PROFA_LEYBL)}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel fs-14">Ссылка на файл:</p>
                                                        <input value={model?.filenames.replace("/playlist.m3u8", "")} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.filename?.type)*/} type="text" readOnly placeholder="Название файла на сервере" onClick={(e) => {
                                                            if (e.target.value) {
                                                                window.open(e.target.value, '_blank');
                                                            }
                                                        }} />
                                                    </div>
                                                    <div className="formColRowFlexDiv">
                                                        <p className="formColOneRowFlexLabel fs-14">Продолжительность:</p>
                                                        <input {...register("duration", { required: true })} className={"formColOneRowFlexInput fs-14 " /*+ (errors.duration?.type)*/} type="text" readOnly placeholder="Продолжительность файла" />
                                                    </div>
                                                </div>
                                            </Collapse>

                                            <div className="d-flex justify-content-end">
                                                <button type="submit" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <i className="fa fa-spinner fa-2x rotating "></i>
                                                    ) : ("Сохранить")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    {screenshots.length > 0 && <div className="col-md-6">
                                        <div className="admin-form">
                                            <h5 className='text-white'>Кадры</h5>
                                            <div className="row">
                                                {screenshots.map((screenshot, i) => {
                                                    return <div className="col-md-6 mb-4" key={i}>
                                                        <a role="button" className={'d-block' + (selectedScreenshot == screenshot ? ' border border-3 border-success' : '')} onClick={(e) => {
                                                            setSelectedScreenshot(screenshot);

                                                            setValue("file", null);
                                                            trigger('file');

                                                            setPreviewImage(screenshot);
                                                        }}>
                                                            <img src={screenshot} className='w-100 d-block' />
                                                        </a>
                                                    </div>
                                                })}
                                            </div>

                                        </div>
                                    </div>}

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="formColRowFlexDiv">
                                                    <input type="checkbox" className="btn-check" id="is_home" autoComplete="off" {...register("is_home")} />
                                                    <label className="btn btn-outline-success w-100 premiere" htmlFor="is_home">Премьера на главном странице</label>
                                                </div>
                                                {(watchIsHome) && <div className="d-flex mb-2 w-100">
                                                    <div className="text-center me-3">
                                                        <div className="uploaded-image border-radius-5">
                                                            {previewBannerImage ?
                                                                <img className="w-100 d-block" src={previewBannerImage} /> :
                                                                (model && model.banner_image ? <a href={model.banner_image} className="w-100" target="_blank">
                                                                    <img className="w-100 d-block" src={model.banner_image} />
                                                                </a> : <p className="text-white w-100 my-0">Баннер на главном</p>)}
                                                        </div>
                                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                                            bannerImageRef.current.click();
                                                        }}>{previewBannerImage ? "Изменить" : "Загрузить"}</a>
                                                    </div>
                                                </div>}
                                            </div>

                                            {/* <div className="col-md-6">
                                                <div className="formColRowFlexDiv">
                                                    <input type="checkbox" className="btn-check" id="is_page" autoComplete="off" {...register("is_page")} />
                                                    <label className="btn btn-outline-success w-100 premiere" htmlFor="is_page">Премьера на странице фильмы</label>
                                                </div>

                                                {(watchIsPage) && <div className="d-flex mb-2 w-100">
                                                    <div className="text-center me-3">
                                                        <div className="uploaded-image border-radius-5">
                                                            {previewPageImage ?
                                                                <img className="w-100 d-block" src={previewPageImage} />
                                                                : (model && model.page_image ? <a href={model.page_image} className="w-100" target="_blank">
                                                                    <img className="w-100 d-block" src={model.page_image} />
                                                                </a> : <p className="text-white w-100 my-0">Баннер на странице фильмы</p>)}
                                                        </div>
                                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                                            pageImageRef.current.click();
                                                        }}>{previewPageImage ? "Изменить" : "Загрузить"}</a>
                                                    </div>
                                                </div>}
                                            </div> */}
                                        </div>
                                        <div>
                                            {watchIsHome && <>
                                                <div className="formColRowFlexDiv">
                                                    <input {...register("subtitle_uz", { required: false })} className={"formColOneRowFlexInput w-100 " + (errors.subtitle_uz?.type)} type="text" placeholder="Введите текст для баннера на узбекском" />
                                                </div>
                                                <div className="formColRowFlexDiv">
                                                    <input {...register("subtitle_ru", { required: false })} className={"formColOneRowFlexInput w-100 " + (errors.subtitle_ru?.type)} type="text" placeholder="Введите текст для баннера на русском" />
                                                </div>
                                            </>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}