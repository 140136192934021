import React from 'react';
import { useForm } from 'react-hook-form';
import { get, getRequest, post } from '../../utils/request';
import { useState } from 'react';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';

export default function MusicLink({ id }) {
    const [model, setModel] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const isMounted = useIsMounted();

    const defaultValues = {
        url: "",
        vk_music_link: "",
        deezer_link: "",
        amazon_link: "",
        tidal_link: "",
        anghami_link: "",
        yandex_music_link: "",
        spotify_link: "",
        apple_music_link: "",
        zvuk_link: "",
        itunes_link: "",
        youtube_music_link: ""
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, trigger } = useForm({
        defaultValues
    });

    React.useEffect(() => {

        const fetchData = async (id) => {
            setIsLoading(true);

            let res = await getRequest(`music/get-links?id=${id}`);

            if (isMounted) {
                setModel(res);

                setIsLoading(false);
            }
        }

        if (id) {
            fetchData(id);
        }
    }, [id]);

    React.useEffect(() => {
        if (model) {
            reset({
                url: model.url,
                vk_music_link: model.vk_music_link,
                deezer_link: model.deezer_link,
                amazon_link: model.amazon_link,
                tidal_link: model.tidal_link,
                anghami_link: model.anghami_link,
                yandex_music_link: model.yandex_music_link,
                spotify_link: model.spotify_link,
                apple_music_link: model.apple_music_link,
                zvuk_link: model.zvuk_link,
                itunes_link: model.itunes_link,
                youtube_music_link: model.youtube_music_link,
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`music/save-links${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
        }));

        if (res?.status === "success") {
            if (isMounted) {
                setModel(res.model);
                toast.success(res.message);
            }
        } else {
            for (const key in res.errors) {
                if (Object.hasOwnProperty.call(res.errors, key)) {
                    const element = res.errors[key];

                    toast.error(element.join(", "));
                }
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    return (
        <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
            <h5 className="text-white mb-3 d-flex justify-content-between"><span>Площадки</span> <small><a role='button' className='text-white' onClick={() => setCollapse(!collapse)}>{collapse ? "скрыть" : "открыть"}</a></small></h5>
            {model && <div className=''>
                <a className='text-white' href={"https://rizanova.uz/" + model.url}>Ссылка UZ <i className="fa fa-link"></i></a>
                <a className='text-white ms-4' href={"https://rizanova.uz/ru/" + model.url}>Ссылка RU <i className="fa fa-link"></i></a>
            </div>}

            <div className={collapse ? 'show mt-4' : "d-none"}>
                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Уникальный URL:</p>
                    <input {...register("url", { required: false })} className={"formColOneRowFlexInput " + (errors.url?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">VK Музыка:</p>
                    <input {...register("vk_music_link", { required: false })} className={"formColOneRowFlexInput " + (errors.vk_music_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Deezer:</p>
                    <input {...register("deezer_link", { required: false })} className={"formColOneRowFlexInput " + (errors.deezer_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Amazon:</p>
                    <input {...register("amazon_link", { required: false })} className={"formColOneRowFlexInput " + (errors.amazon_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Tidal:</p>
                    <input {...register("tidal_link", { required: false })} className={"formColOneRowFlexInput " + (errors.tidal_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Anghami:</p>
                    <input {...register("anghami_link", { required: false })} className={"formColOneRowFlexInput " + (errors.anghami_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Yandex Music:</p>
                    <input {...register("yandex_music_link", { required: false })} className={"formColOneRowFlexInput " + (errors.yandex_music_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Spotify:</p>
                    <input {...register("spotify_link", { required: false })} className={"formColOneRowFlexInput " + (errors.spotify_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Apple Music:</p>
                    <input {...register("apple_music_link", { required: false })} className={"formColOneRowFlexInput " + (errors.apple_music_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Звук:</p>
                    <input {...register("zvuk_link", { required: false })} className={"formColOneRowFlexInput " + (errors.zvuk_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">iTunes:</p>
                    <input {...register("itunes_link", { required: false })} className={"formColOneRowFlexInput " + (errors.itunes_link?.type)} type="text" />
                </div>

                <div className="formColRowFlexDiv">
                    <p className="formColOneRowFlexLabel">Youtube Music:</p>
                    <input {...register("youtube_music_link", { required: false })} className={"formColOneRowFlexInput " + (errors.youtube_music_link?.type)} type="text" />
                </div>

                <div className="d-flex justify-content-end">
                    <button type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <i className="fa fa-spinner fa-2x rotating "></i>
                        ) : ("Сохранить")}
                    </button>
                </div>
            </div>
        </form>
    )
}