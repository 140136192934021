import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import MusicFormInline from "./forms/MusicFormInline";

export default function MultiEditMusic(props) {
    let { ids, album_id } = useParams();
    let history = useHistory();
    const [updated, setUpdated] = useState([]);

    ids = ids.split(",");

    const update = (id) => {
        setUpdated([...updated, Number.parseInt(id)]);
    }

    useEffect(() => {
        if (updated.length == ids.length) {
            history.push("/music");
        }
    }, [updated]);

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">

                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-5">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        Редактировать объектов
                                    </span>
                                </h3>
                                <NavLink to={album_id ? `/album/${album_id}` : `/music`} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>
                        {ids.filter(id => updated.indexOf(Number.parseInt(id)) == -1).map(id => {
                            return <div className="mb-5" key={id}>
                                <MusicFormInline id={id} update={update} />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}