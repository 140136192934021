import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../components/context';
import { get, post } from '../../../utils/request';
import { arrayToFormData, useIsMounted } from '../../../utils/functions';
import { toast } from 'react-toastify';
import AddMusicToPlaylist from '../../../components/AddMusicToPlaylist';

const controllerName = "playlist";
const title = "Плейлист";

export default function PlaylistHandle() {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [isOpenAddPlaylist, setIsOpenAddPlaylist] = useState(false);

    const isMounted = useIsMounted();

    const { id } = useParams();

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    let [previewImage, setPreviewImage] = useState(null);
    let history = useHistory();

    const imageRef = useRef();

    const fetchData = async () => {
        setIsLoading(true);

        let res = await get(`${controllerName}/get?id=${id}`);

        if (isMounted) {
            setModel(res);

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        if (id) {
            fetchData();
        }
    }, []);

    React.useEffect(() => {
        if (model) {
            reset(model);
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : null,
            sort: data.sort ? data.sort : 0
        }));

        if (res?.status === "success") {
            if (isMounted) {
                if (!id)
                    history.push(`/settings/${controllerName}/` + res.model.id)
                else setModel(res.model);

                toast.success(res.message);
            }

            authContext.loadCategories();
        } else {
            toast.error(res.message);
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const handleDelete = async (music_id) => {
        if (window.confirm("Удалить из плейлиста?")) {
            let res = await get(`${controllerName}/delete-from-playlist?id=` + music_id + "&playlist_id=" + id);

            if (res?.status === "success") {
                toast.success("Музыка удалена из плейлиста.");

                fetchData();
            }
        }
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-6">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title} {!!model && " — " + model.name_uz}
                                    </span>

                                </h3>
                                <NavLink to={"/settings/" + controllerName} className="show-all btn btn-secondary fs-12">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className="uploaded-image-square border-radius-5">
                                            {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a className="w-100" href={model.image} target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            imageRef.current.click();
                                        }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Название UZ *:</p>
                                                <input {...register("name_uz", { required: true })} className={"formColOneRowFlexInput " + (errors.name_uz?.type)} type="text" placeholder="Введите название на узбекском" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Название RU *:</p>
                                                <input {...register("name_ru", { required: true })} className={"formColOneRowFlexInput " + (errors.name_ru?.type)} type="text" placeholder="Введите название на русском" />
                                            </div>

                                            <div className="formColRowFlexDiv">
                                                <p className="formColOneRowFlexLabel">Приоритет:</p>
                                                <input {...register("sort", { required: false })} className={"formColOneRowFlexInput " + (errors.sort?.type)} type="number" placeholder="Введите значение" />
                                            </div>

                                            <div className="formColRowFlexDiv d-none">
                                                <p className="formColOneRowFlexLabel">Картинка:</p>
                                                <input {...register("file")} type="file" ref={imageRef} accept="image/*" onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var file = e.target.files[0];
                                                        setValue("file", file);
                                                        var reader = new FileReader();
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setPreviewImage([reader.result])
                                                        }
                                                    }
                                                }} className={"formColOneRowFlexInput " + (errors.file?.type)} />
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <button type="submit" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <i className="fa fa-spinner fa-2x rotating "></i>
                                                    ) : ("Сохранить")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="admin-form mt-0">
                                            {model && <>
                                                <p className="text-white">Музыки в плейлисте</p>

                                                <ul className={`list-group list-group-flush ${model.musics.length > 0 ? 'list-group-numbered2' : ''}`}>
                                                    {model.musics.length == 0 && <li className="list-group-item list-group-item-dark">Пока пусто</li>}

                                                    {model.musics.filter(music => music.status != 3).map((music, index) => {
                                                        return (
                                                            <li className="list-group-item list-group-item-dark py-3">
                                                                <div className="d-flex">
                                                                    <a role="button" className="w-100 text-white d-block">
                                                                        {music.name}
                                                                    </a>
                                                                    {<>
                                                                        <a role="button" className="text-white" onClick={() => {
                                                                            handleDelete(music.id);
                                                                        }}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </>}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>

                                                <button type="button" className="btn btn-success mt-3" onClick={() => setIsOpenAddPlaylist(true)}>Добавить из существующих <i className="fa fa-plus"></i></button>

                                                <AddMusicToPlaylist isOpen={isOpenAddPlaylist} setIsOpen={setIsOpenAddPlaylist} playlist_id={model.id} fetchAlbum={fetchData} />
                                            </>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}